// REACT
import React from 'react';
import ReactDOM from 'react-dom';

// APP COMPONENT
import App from 'app/providers/Redux.provider';

// STYLES
import './app/styles/index.scss';

// SERVICE WORKER
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
