// REDUX
import { combineReducers } from 'redux';

// REDUCERS
import { ThemeReducerStateI, themeReducer } from './theme/theme.reducer';
import { CamerasReducerStateI } from './cameras/cameras.types';
import { GroupsReducerStateI } from './groups/groups.types';
import { UsersReducerStateI } from './users/users.types';
import { camerasReducer } from './cameras/cameras.reducer';
import { groupsReducer } from './groups/groups.reducer';
import { usersReducer } from './users/users.reducer';
import { EntitiesReducerStateI } from './entities/entities.types';
import { entitiesReducer } from './entities/entities.reducer';
import { tablesReducer } from './tables/tables.reducer';
import { TablesReducerStateI } from './tables/tables.types';
import { authReducer } from './auth/auth.reducer';
import { AuthReducerStateI } from './auth/auth.types';
import { PartnerReducerStateI, partnerReducer } from './partner/partner.reducers';

export type AppStateI = {
  auth: AuthReducerStateI;
  theme: ThemeReducerStateI;
  cameras: CamerasReducerStateI;
  groups: GroupsReducerStateI;
  users: UsersReducerStateI;
  entities: EntitiesReducerStateI;
  tables: TablesReducerStateI;
  partner: PartnerReducerStateI;
};

const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  cameras: camerasReducer,
  groups: groupsReducer,
  users: usersReducer,
  entities: entitiesReducer,
  tables: tablesReducer,
  partner: partnerReducer
});

export default rootReducer;
