// REACT
import React, { Component } from 'react';
import { connect } from 'react-redux';

// STORE 
import { AppStateI } from 'state/modules/root.reducer';

// SELECTORS
import { isThemeDarkSelector } from 'state/selectors/modules/theme.selectors';

// MATERIAL STYLES
import { ThemeProvider } from '@material-ui/styles';

// OWL + MATERIAL THEME
import { OwlDarkTheme, OwlLightTheme } from './Material.themes';

// MATERIAL CORE 
import { CssBaseline } from '@material-ui/core';

// APP COMPONENTS
import App from './Auth.provider';

// STYLES
import 'app/styles/App.scss';

class AppWithProviderMaterial extends Component<Props> {
  render() {
    const { isThemeDark } = this.props;
    const muiTheme = isThemeDark ? OwlDarkTheme : OwlLightTheme;
    return (
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    );
  }
}

// REDUX MAPPING
const mapStateToProps = (state: AppStateI) => ({
  isThemeDark: isThemeDarkSelector(state),
});

type Props = Partial<ReturnType<typeof mapStateToProps>>;

export default connect(
  mapStateToProps,
  null,
)(AppWithProviderMaterial);