// ACTIONS
import { AuthActionsUnion } from './auth.actions';
import { AuthActionType, AuthReducerStateI } from './auth.types';

// REDUCER UTILS
import { mergeState } from 'state/modules/module.reducers';

const initialState: AuthReducerStateI = {
  userId: undefined,
  request: undefined,
  isRequesting: false,
  isSuccess: false,
  errorMessage: undefined,
};

export function authReducer(state: AuthReducerStateI = initialState, action: AuthActionsUnion) {
  switch (action.type) {
    case AuthActionType.SIGN_IN_REQUEST: 
    case AuthActionType.SIGN_OUT_REQUEST: 
      return mergeState(state, {
        userId: undefined,
        request: action.type,
        isRequesting: true,
        isSuccess: false,
        errorMessage: undefined,
      });
    case AuthActionType.SIGN_IN_FAILURE: 
    case AuthActionType.SIGN_OUT_FAILURE: 
      return mergeState(state, {
        isRequesting: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      });
    case AuthActionType.SIGN_IN_SUCCESS: {
      return mergeState(state, {
        userId: action.userId,
        isRequesting: false,
        isSuccess: true,
      });
    }
    case AuthActionType.SIGN_OUT_SUCCESS: {
      return mergeState(state, {
        isRequesting: false,
        isSuccess: true,
      });
    }
    default:
      return state;
  }
}
