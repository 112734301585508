// ACTIONS
import { UsersActionsUnion } from './users.actions';
import { UsersActionType, UsersReducerStateI } from './users.types';

// REDUCER UTILS
import { mergeState } from 'state/modules/module.reducers';

// SUB REDUCER
import { userReducer } from './reducers/user.reducer';

const initialState: UsersReducerStateI = {
  items: [],
  isValid: false,
  isFetching: false,
  errorMessage: undefined,
  user: {
    request: undefined,
    isRequesting: false,
    isSuccess: false,
    errorMessage: undefined,
  }
};

export function usersReducer(state: UsersReducerStateI = initialState, action: UsersActionsUnion) {
  switch (action.type) {
    case UsersActionType.GET_USERS_REQUEST: {
      return mergeState(state, {
        isValid: false,
        isFetching: true,
        errorMessage: undefined,
      });
    }
    case UsersActionType.GET_USERS_SUCCESS: {
      return mergeState(state, {
        items: action.result,
        isValid: true,
        isFetching: false,
      });
    }
    case UsersActionType.GET_USERS_FAILURE: {
      return mergeState(state, {
        isValid: true,
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    }
    case UsersActionType.CREATE_USER_SUCCESS:
      return mergeState(state, {
        items: [...state.items, ...action.result],
        isValid: true,
        isFetching: false,
        user: userReducer(state.user, action)
      });
    case UsersActionType.DELETE_USER_SUCCESS:
      return mergeState(state, {
        items: state.items.filter(item => item !== action.userId),
        isValid: true,
        isFetching: false,
        user: userReducer(state.user, action)
      });
    case UsersActionType.CREATE_USER_REQUEST:
    case UsersActionType.CREATE_USER_FAILURE:
    case UsersActionType.UPDATE_USER_PERMISSION_REQUEST:
    case UsersActionType.UPDATE_USER_PERMISSION_SUCCESS:
    case UsersActionType.UPDATE_USER_PERMISSION_FAILURE:
    case UsersActionType.DELETE_USER_REQUEST:
    case UsersActionType.DELETE_USER_FAILURE:
      return {
        ...state,
        user: userReducer(state.user, action)
      }
    default:
      return state;
  }
}
