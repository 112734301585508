// react
import React from 'react';

// MATERIAL COMPONENT
import { CircularProgress } from '@material-ui/core';

// styles
import 'app/styles/components/loading.styles.scss';

export const Loading: React.FC<{}> = () => {
  return (
    <div className="loading-div">
      <CircularProgress color="secondary" />
    </div>
  )
}