import { createSelector } from 'reselect';
import { AppStateI } from 'state/modules/root.reducer';
import { getGroupEntities } from './entities.selectors';
import { GroupI } from 'models/Group/Group.model';

export const getGroupsFetchingState = (state: AppStateI) => state.groups.isFetching;
export const getGroupsValidState = (state: AppStateI) => state.groups.isValid;
export const getGroupItems = (state: AppStateI) => state.groups.items;

export const shouldFetchGroupsSelector = createSelector(
  [getGroupsFetchingState, getGroupsValidState],
  (isFetching, isValid) => {
    let shouldFetch = false;
    if (!isFetching && !isValid) {
      shouldFetch = true;
    }
    return shouldFetch;
  },
);


export const getGroupsArray = createSelector(
  [getGroupsValidState, getGroupItems, getGroupEntities],
  (isValid, items, entities) => {
    let groups: GroupI[] = [];
    if (!isValid) {
      return groups;
    }
    groups = items.map((itemId) => entities[itemId]);
    return groups;
  }
)

export const getGroupIdToNameMap = createSelector(
  [getGroupsArray],
  (groups) => {
    let groupIdToNameMap: Map<string, string | undefined> = new Map();
    groups.forEach((group) => {
      groupIdToNameMap.set(group.groupId, group.name);
    });
    return groupIdToNameMap;
  }
)

export const GROUP_ALL = 'all';

const DEFAULT_GROUPS = [
  // {
  //   value: 'all',
  //   displayValue: 'All'
  // },
  {
    value: 'unassigned',
    displayValue: 'Unassigned'
  },
];

export const getGroupsForTableFilterDropdown = createSelector(
  [getGroupsArray], 
  (groups) => {
    let groupsForDropdown = groups.map(group => {
      return {
        value: group.groupId,
        displayValue: group.name
      }
    });
    return DEFAULT_GROUPS.concat(groupsForDropdown);
  }
)

export const getGroupsForCameraDialogDropdown = createSelector(
  [getGroupsArray], 
  (groups) => {
    let groupsForDropdown = groups.map(group => {
      return {
        value: group.groupId,
        displayValue: group.name
      }
    });
    return DEFAULT_GROUPS.concat(groupsForDropdown);
  }
)