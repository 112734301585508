// REACT-REDUX
import React, { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// APP STATE
import { AppStateI } from 'state/modules/root.reducer';

// ACTIONS
import { conditionalGetUsersThunk, updateUserPermissionThunk, createUserThunk, deleteUserThunk } from 'state/modules/users/users.thunks';

// SELECTORS
import { getPaginatedUserTableItems, getUserTableCount, getUserTablePage, getUserTableRowsPerPage, getSelectedUser, getSelectedUserId } from 'state/selectors/tables/tables.user.selectors';
import { selectTableItemUserTable, unselectTableItemUserTable, setPageUserTable, setRowsPerPageUserTable } from 'state/modules/tables/tables.actions';

// MATERIAL COMPONENTS
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';

// MATERIAL ICONS
import AddIcon from '@material-ui/icons/Add';

// APP COMPONENTS
import UserTable from 'app/components/Tables/UserTable/UserTable';
import UserDialog from 'app/containers/UserDialog/UserDialog';
import { ConfirmationDialog } from 'app/components/Dialogs/ConfirmationDialog';
import { UserCreateParams } from 'models/User/User.model';
import { isAuthUserAdminSelector } from 'state/selectors/modules/auth.selectors';

interface StateI {
  isCreateDialogOpen: boolean;
  isCreateDialogRequesting: boolean;
  isDeleteDialogOpen: boolean;
  isDeleteDialogRequesting: boolean;
}

const initialState: StateI = {
  isCreateDialogOpen: false,
  isCreateDialogRequesting: false,
  isDeleteDialogOpen: false,
  isDeleteDialogRequesting: false,
}

class UsersPage extends Component<PropsI, StateI> {

  state = initialState;

  componentDidMount = () => {
    const { conditionalGetUsersThunk } = this.props;
    conditionalGetUsersThunk();
  }

  static getDerivedStateFromProps (props: PropsI, state: StateI) {
    console.log('getDerivedStateFromProps');
    console.log(state);
    console.log('props.isSuccess', props.isSuccess);
    if (state.isCreateDialogOpen && state.isCreateDialogRequesting && props.isSuccess) {
      return { isCreateDialogOpen: false, isCreateDialogRequesting: false }
    } else if (state.isDeleteDialogOpen && state.isDeleteDialogRequesting && props.isSuccess) {
      return { isDeleteDialogOpen: false, isDeleteDialogRequesting: false }
    } else {
      return null;
    }
  }

  toggleCreateDialog = () => {
    let { isCreateDialogOpen } = this.state;
    this.setState({ isCreateDialogOpen: !isCreateDialogOpen });
  }

  toggleDeleteDialog = () => {
    let { isDeleteDialogOpen } = this.state;
    this.setState({ isDeleteDialogOpen: !isDeleteDialogOpen });
  }

  handleCreateUser = (user: UserCreateParams) => {
    const { createUserThunk } = this.props;
    createUserThunk(user);
    this.setState({ isCreateDialogRequesting: true });
  }

  handleDeleteUser = () => {
    const { selectedUserId, deleteUserThunk } = this.props;
    console.log('handleDeleteUser');
    if (!selectedUserId) {
      return;
    }

    this.setState({ isDeleteDialogRequesting: true });
    deleteUserThunk(selectedUserId);
  }

  render() {
    const { isAuthUserAdmin, selectedUser } = this.props;
    const { isCreateDialogOpen, isDeleteDialogOpen } = this.state;
    let selectedUserEmail = ""; 
    if (selectedUser && selectedUser.email) {
      selectedUserEmail = selectedUser.email;
    }

    return (
      <div>
        <Card>
          <CardHeader
            title="Users"
            subheader={"Control permissions for admins and managers"}
            titleTypographyProps={{ variant: 'h6' }}
            subheaderTypographyProps={{ variant: 'subtitle2' }}
            action={
              <Button
                aria-label="create-user" 
                onClick={this.toggleCreateDialog}
                startIcon={<AddIcon />}
                disabled={!isAuthUserAdmin}
              > 
                Create User
              </Button>
            }
          />
          <CardContent>
            <UserTable 
              {...this.props} 
              handleDelete={this.toggleDeleteDialog}
            />
          </CardContent>
        </Card>
        <UserDialog
          isOpen={isCreateDialogOpen}
          handleClose={this.toggleCreateDialog}
          handleCreateUser={this.handleCreateUser}
        />
        <ConfirmationDialog
          title={'Delete User'}
          text={`Please confirm that you would like to delete user ${selectedUserEmail}. Deleting a user is permanent and cannot be undone.`}
          isOpen={isDeleteDialogOpen}
          handleConfirm={this.handleDeleteUser}
          handleCancel={this.toggleDeleteDialog}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppStateI) => ({
  isAuthUserAdmin: isAuthUserAdminSelector(state),
  isSuccess: state.users.user.isSuccess,
  items: getPaginatedUserTableItems(state),
  count: getUserTableCount(state),
  page: getUserTablePage(state),
  rowsPerPage: getUserTableRowsPerPage(state),
  selectedUser: getSelectedUser(state),
  selectedUserId: getSelectedUserId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      conditionalGetUsersThunk: conditionalGetUsersThunk,
      handleSelect: selectTableItemUserTable,
      handleUnselect: unselectTableItemUserTable,
      handleUpdatePermission: updateUserPermissionThunk,
      createUserThunk: createUserThunk,
      deleteUserThunk: deleteUserThunk,
      setPage: setPageUserTable,
      setRowsPerPage: setRowsPerPageUserTable,
    },
    dispatch,
  );

type PropsI = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface UserTableRowHandlersLocalStateI {
  handleDelete: () => void;
}

export type UserTableRowHandlers = UserTableRowHandlersLocalStateI & Pick<ReturnType<typeof mapDispatchToProps>, 'handleSelect' | 'handleUnselect' | 'handleUpdatePermission'> & Pick<ReturnType<typeof mapStateToProps>, 'isAuthUserAdmin'>;

export type UserTablePropsI = ReturnType<typeof mapStateToProps> & 
  Pick<ReturnType<typeof mapDispatchToProps>, 'handleSelect' | 'handleUnselect' | 'handleUpdatePermission' | 'setPage' | 'setRowsPerPage'> & 
  UserTableRowHandlersLocalStateI;


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersPage);