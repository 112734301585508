// MODULE TYPES
import { CameraActionsUnion } from '../cameras.actions';
import { CamerasActionType, CamerasReducerStateI } from '../cameras.types';

// REDUX UTILS
import { mergeState } from 'state/modules/module.reducers';

export function cameraReducer(state: CamerasReducerStateI['camera'], action: CameraActionsUnion) {
  switch (action.type) {
    case CamerasActionType.ADD_CAMERA_REQUEST: 
    case CamerasActionType.REMOVE_CAMERA_REQUEST:
    case CamerasActionType.UPDATE_CAMERA_METADATA_REQUEST:
      return mergeState(state, {
        request: action.type,
        isRequesting: true,
        isSuccess: false,
      });
    case CamerasActionType.REMOVE_CAMERA_SUCCESS:
    case CamerasActionType.ADD_CAMERA_SUCCESS:
    case CamerasActionType.UPDATE_CAMERA_METADATA_SUCCESS:
      return mergeState(state, {
        isRequesting: false,
        isSuccess: true,
      });
    case CamerasActionType.ADD_CAMERA_FAILURE:
    case CamerasActionType.REMOVE_CAMERA_FAILURE: 
    case CamerasActionType.UPDATE_CAMERA_METADATA_FAILURE:
      return mergeState(state, {
        isRequesting: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      });
    default: 
      return state;
  }
} 