// REACT-REDUX
import React, { Component } from 'react';

// MODELS
import { GroupCreateParams, GroupCreateDefault } from 'models/Group/Group.model';

// MATERIAL COMPONENTS
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

// APP COMPONENTS
import { TextFieldSafeEmail, TextFieldSafe } from 'app/components/Forms/TextField';
// import UserCreateForm from 'app/components/Forms/UserCreateForm';

interface PropsI {
  isOpen: boolean;
  handleCreateGroup: (group: GroupCreateParams) => void;
  handleClose: () => void;
}

type State = GroupCreateParams

interface HandlerPropsI {
  handleChangeTextField: (key: keyof State, value: string) => void;
}

// FORM FIELDS 
class TextFieldName extends TextFieldSafe<State, 'name'> {}
class TextFieldAddress extends TextFieldSafe<State, 'address'> {}
class TextFieldCity extends TextFieldSafe<State, 'city'> {}
class TextFieldState extends TextFieldSafe<State, 'state'> {}
class TextFieldCountry extends TextFieldSafe<State, 'country'> {}
class TextFieldContactPrimaryFirstname extends TextFieldSafe<State, 'contactPrimaryFirstname'> {}
class TextFieldContactPrimaryLastname extends TextFieldSafe<State, 'contactPrimaryLastname'> {}
class TextFieldContactEmail extends TextFieldSafeEmail<State, 'contactEmail'> {}
class TextFieldContactPhoneNumber extends TextFieldSafe<State, 'contactPhoneNumber'> {}

export default class GroupDialog extends Component<PropsI, State> {

  state: State = GroupCreateDefault;

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState(GroupCreateDefault);
    handleClose();
  }

  handleChangeTextField = (key: keyof State, value: string) => {
    switch (key) {
      case 'name': 
        this.setState({ name: value });
        break;
      case 'address': 
        this.setState({ address: value });
        break;
      case 'city': 
        this.setState({ city: value });
        break;
      case 'state': 
        this.setState({ state: value });
        break;
      case 'country': 
        this.setState({ country: value });
        break;
      case 'contactPrimaryFirstname': 
        this.setState({ contactPrimaryFirstname: value });
        break;
      case 'contactPrimaryLastname': 
        this.setState({ contactPrimaryLastname: value });
        break;
      case 'contactEmail': 
        this.setState({ contactEmail: value });
        break;
      case 'contactPhoneNumber': 
        this.setState({ contactPhoneNumber: value });
        break;
      default:
        return;
    }
  }

  handleCreateGroup = () => {
    const { handleCreateGroup } = this.props;
    handleCreateGroup(this.state);
  }

  render() {
    const { isOpen, handleClose } = this.props;
    const { name, contactEmail } = this.state;
    const isUserValid = name.length > 1 && contactEmail.length > 5;
    return (
      <Dialog open={isOpen} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">Create Group</DialogTitle>
        <DialogContent className="group-dialog-grid">
          <TextFieldName
            value={this.state.name}
            propertyKey={'name'}
            label={'Group Name'}
            className="group-dialog-name"
            handleChange={this.handleChangeTextField}
          />
          <TextFieldAddress
            value={this.state.address}
            propertyKey={'address'}
            label={'Primary Address'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldCity
            value={this.state.city}
            propertyKey={'city'}
            label={'City'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldState
            value={this.state.state}
            propertyKey={'state'}
            label={'State'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldCountry
            value={this.state.country}
            propertyKey={'country'}
            label={'Country'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldContactPrimaryFirstname
            value={this.state.contactPrimaryFirstname}
            propertyKey={'contactPrimaryFirstname'}
            label={'Primary Contact - First Name'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldContactPrimaryLastname
            value={this.state.contactPrimaryLastname}
            propertyKey={'contactPrimaryLastname'}
            label={'Last Name'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldContactEmail
            value={this.state.contactEmail}
            propertyKey={'contactEmail'}
            label={'Email'}
            handleChange={this.handleChangeTextField}
          />
          <TextFieldContactPhoneNumber
            value={this.state.contactPhoneNumber}
            propertyKey={'contactPhoneNumber'}
            label={'Phone Number'}
            handleChange={this.handleChangeTextField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateGroup} color="secondary" disabled={!isUserValid}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}