// react
import React, { Component } from 'react';

// MATERIAL COMPONENT
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

// APP COMPONENT
import { TextFieldSafe, TextFieldSafeEmail } from './TextField';

// APP COMPONENT
import { UserPermissionRadio } from './UserPermissionRadio';

// PROPS
import { UserCreateFormProps } from 'app/containers/UserDialog/UserDialog';

// STYLES 
import 'app/styles/components/forms.styles.scss'

// FORM FIELDS 
class TextFieldFirstName extends TextFieldSafe<UserCreateFormProps, 'firstName'> { }
class TextFieldLastName extends TextFieldSafe<UserCreateFormProps, 'lastName'> { }
class TextFieldEmail extends TextFieldSafeEmail<UserCreateFormProps, 'email'> { }

export default class UserCreateForm extends Component<UserCreateFormProps> {
  render() {
    return (
      <FormGroup>
        <FormControl className="user-create-form">
          <TextFieldEmail
            label="Email"
            propertyKey={'email'}
            value={this.props.email}
            handleChange={this.props.handleChangeTextField}
          />
          <TextFieldFirstName
            label="First name"
            propertyKey={'firstName'}
            value={this.props.firstName}
            handleChange={this.props.handleChangeTextField}
          />
          <TextFieldLastName
            label="Last name"
            propertyKey="lastName"
            value={this.props.lastName}
            handleChange={this.props.handleChangeTextField}
          />
          <div className="user-permission-row">
            <FormLabel>User permission role</FormLabel>
            <UserPermissionRadio
              value={this.props.permission}
              handleChange={this.props.handleChangePermission}
            />
          </div>
        </FormControl>
      </FormGroup>
    )
  }
}