// REACT
import React, { Component } from 'react';

// REACT-REDUX
import { Provider } from 'react-redux';

// COMPONENT 
import AppWithProviderMaterial from './Material.provider';

// CONFIGURE STORE
import store from 'state/store/store';

export default class AppWithProviderRedux extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppWithProviderMaterial />
      </Provider>
    );
  }
}
