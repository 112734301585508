// REDUX
import { applyMiddleware, createStore } from 'redux'

// MIDDLEWARE
import thunkMiddleware from 'redux-thunk'

// REDUCER
import rootReducer from 'state/modules/root.reducer';


const configureStore = () => createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);

export default configureStore;