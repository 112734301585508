// MODEL
import { UserI, OwlPartnerPoolUserPermission, UserAdmin } from './User.model';

// UTIL
import { safeObjectHasProperty } from "utils/typesafe";

export function userTypeGuard(user: UserI | undefined | null): user is UserI {
  if (user && safeObjectHasProperty(user, 'userId'))  {
    return true;
  }
  return false;
}

// export function userHasIdTypeGuard(user: UserI): user is UserWithId {
//   if (user.userId && typeof user.userId === 'string') {
//     return true;
//   }
//   return false;
// }

// export function filterUsersIdTypeGuard(users: UserI[]): UserWithId[] {
//   let userWithIdArray: UserWithId[] = [];
//   users.forEach((user) => {
//     if (userHasIdTypeGuard(user)) {
//       userWithIdArray.push(user);
//     }
//   });
//   return userWithIdArray;
// }

export function userAdminTypeGuard(user: UserI | undefined | null): user is UserAdmin {
  if (userTypeGuard(user)) {
    return user.permission === OwlPartnerPoolUserPermission.ADMIN;
  }
  return false;
}

