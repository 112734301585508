// ACTIONS
import { ThemeActionType } from '../theme/theme.types';
import { ThemeActionsUnion } from '../theme/theme.actions';

// UTILS
import { mergeState } from '../module.reducers';

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light'
}

export interface ThemeReducerStateI {
  theme: ThemeMode;
}

const initialState: ThemeReducerStateI = {
  theme: ThemeMode.DARK,
};

// if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_MODE === 'camera_selected') {
// }

export function themeReducer(state: ThemeReducerStateI = initialState, action: ThemeActionsUnion) {
  switch (action.type) {
    case ThemeActionType.SET_THEME: {
      return mergeState(state, {
        theme: action.theme
      });
    }
    default:
      return state;
  }
}