// ENUM
import { UserPermissionEnumForSchema } from './User.model';

// SCHEMA MODEL
import { SchemaArrayI } from 'models/models.validation';

export const UserSchema = {
  type: 'object',
  properties: {
    "userId": { "type": "string" },
    "partnerPoolId": { "type": "string" },
    "dateCreated": { "type": "number" },
    "dateLastModified": { "type": "number" },
    "modifiedByUserId": { "type": "string" },
    "createdByUserId": { "type": "string" },
    "permission": { "type": "string", "enum": UserPermissionEnumForSchema },
    "firstName": { "type": "string" },
    "lastName": { "type": "string" },
    "confirmed": { "type": "boolean" },
    "email": { "type": "string" },
  },
  required: ['partnerPoolId', 'userId', 'email', 'permission', 'dateCreated', 'confirmed', 'firstName', 'lastName'],
};

export const UserArraySchema: SchemaArrayI = {
  type: 'array',
  items: UserSchema,
};

