import { AppStateI } from './root.reducer';

export function mergeState<T>(currentState: T, stateDelta: Partial<T>) {
  return {
    ...currentState,
    ...stateDelta,
  };
}

export type GetState = () => AppStateI;

export interface AsyncItemStateI<T> {
  item: T | undefined;
  isFetching: boolean;
  isValid: boolean;
  errorMessage?: string;
}

export const initialAsyncItemState = {
  item: undefined,
  isFetching: false,
  isValid: false,
}

export interface AsyncItemsStateI<T> {
  items: T[];
  isFetching: boolean;
  isValid: boolean;
  errorMessage?: string;
}

export interface AsyncRequestStateI {
  request: string | undefined;
  isRequesting: boolean;
  isSuccess: boolean;
  errorMessage: string | undefined;
}