// react
import React from 'react';

// MATERIAL COMPONENTS
import { TableRow, TableHead, TableCell } from '@material-ui/core';

// PROPS
import { GroupTableRowProps } from './GroupTable';

export const GroupTableHeader: React.FC<Pick<GroupTableRowProps, 'externalProvider'>>= ({ externalProvider }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Group Name</TableCell>
        <TableCell align="left">Contact</TableCell>
        {externalProvider === 'geotab' && (
          <TableCell align="left" colSpan={2}>Geotab Company Id</TableCell>  
        )}
        <TableCell align="left"></TableCell>
        {/* <TableCell align="left">Time</TableCell> */}
        {/* <TableCell align="left">Location</TableCell> */}
        {/* <TableCell align="left">Credits</TableCell> */}
      </TableRow>
    </TableHead>
  )
}