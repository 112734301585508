// MODULE ACTION TYPES
import { createAction, ActionsUnionT } from "../module.actions";

// ACTION TYPES
import { PartnerActionTypes } from "./partner.types";

// MODEL
import { PartnerI } from "models/Partner/Partner.model";

export const getPartnerRequestActionCreator = (partnerPoolId: string) => createAction(PartnerActionTypes.GET_PARTNER_REQUEST, { partnerPoolId });
export type GetPartnerRequestActionT = ReturnType<typeof getPartnerRequestActionCreator>; 
  
export const getPartnerSuccessActionCreator = (partner: PartnerI) => createAction(PartnerActionTypes.GET_PARTNER_SUCCESS, { partner });
export type GetPartnerSuccessActionT = ReturnType<typeof getPartnerSuccessActionCreator>; 
  
export const getPartnerFailureActionCreator = (errorMessage: string) => createAction(PartnerActionTypes.GET_PARTNER_FAILURE, { errorMessage });
export type GetPartnerFailureActionT = ReturnType<typeof getPartnerFailureActionCreator>; 
  
export const PartnerActionCreators = {
  getPartnerRequest: getPartnerRequestActionCreator,
  getPartnerSuccess: getPartnerSuccessActionCreator,
  getPartnerFailure: getPartnerFailureActionCreator,
}

export type PartnerActionsUnion = ActionsUnionT<typeof PartnerActionCreators>;
export type PartnerEntityActionsUnion = GetPartnerSuccessActionT;