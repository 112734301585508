// REACT-REDUX
import React, { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// APP STATE
import { AppStateI } from 'state/modules/root.reducer';

// ACTIONS
import { getCamerasThunk, addCameraToGroupThunk, removeCameraFromGroupThunk, updateCameraMetadataThunk } from 'state/modules/cameras/cameras.thunks';

// SELECTORS
import { getPaginatedCameraTableItems, getCameraTableCount, getCameraTablePage, getCameraTableRowsPerPage, getSelectedCameraSerial, isSelectedCameraInGroup, getSelectedCameraMetadata } from 'state/selectors/tables/tables.camera.selectors';
import { selectTableItemCameraTable, unselectTableItemCameraTable, setPageCameraTable, setRowsPerPageCameraTable } from 'state/modules/tables/tables.actions';
import { getSelectedGroupIdForDropdown } from 'state/selectors/tables/tables.group.selectors';
import { getGroupsForTableFilterDropdown, getGroupsForCameraDialogDropdown } from 'state/selectors/modules/groups.selectors';

// ACTIONS
import { conditionalGetGroupsThunk, selectGroupDropdownThunk } from 'state/modules/groups/groups.thunks';

// MATERIAL COMPONENTS
import { Card, CardContent, CardHeader, CircularProgress } from '@material-ui/core';

// APP COMPONENTS
import CameraTable from 'app/components/Tables/CameraTable/CameraTable';
import CameraDialog from 'app/containers/CameraDialog/CameraDialog';
import { DropdownSelector } from 'app/components/Selector/DropdownSelector';

// STYLES
import 'app/styles/pages/camera.styles.scss';
import { getCamerasFetchingState } from 'state/selectors/modules/cameras.selectors';
import CameraMetadataDialog from 'app/containers/CameraDialog/CameraMetadataDialog';

interface StateI {
  isCameraDialogOpen: boolean;
  isCameraDialogRequesting: boolean;
  isAssetDialogOpen: boolean;
  isAssetDialogRequesting: boolean;
  isBatchCamerasDialogOpen: boolean;
}

const initialState: StateI = {
  isCameraDialogOpen: false,
  isCameraDialogRequesting: false,
  isAssetDialogOpen: false,
  isAssetDialogRequesting: false,
  isBatchCamerasDialogOpen: false,
}

export interface CameraTableHandlerPropsI {
  toggleCameraDialog: () => void;
  toggleAssetDialog: () => void;
}

export type CameraMetadataDialogProps = {
  isOpen: boolean;
  isRequesting: boolean;
  selectedCameraMetadata: string | undefined;
  selectedCameraSerial: string | undefined;
  handleClose: () => void;
  handleUpdateCameraMetadata: (externalMetadata: string | undefined) => void;
} 

class CamerasPage extends Component<PropsI, StateI> {
  
  state = initialState;

  static getDerivedStateFromProps (props: PropsI, state: StateI) {
    if (state.isCameraDialogOpen && state.isCameraDialogRequesting && props.isSuccess) {
      return { isCameraDialogOpen: false, isCameraDialogRequesting: false }
    } else if (state.isAssetDialogOpen && state.isAssetDialogRequesting && props.isSuccess) {
      return { isAssetDialogOpen: false, isAssetDialogRequesting: false }
    } else {
      return null;
    }
  }

  componentDidMount = () => {
    const { getCamerasThunk, conditionalGetGroupsThunk } = this.props;
    getCamerasThunk();
    conditionalGetGroupsThunk();
  }

  toggleCameraDialog = () => {
    let { isCameraDialogOpen } = this.state;
    this.setState({ isCameraDialogOpen: !isCameraDialogOpen });
  }

  toggleAssetDialog = () => {
    let { isAssetDialogOpen } = this.state;
    this.setState({ isAssetDialogOpen: !isAssetDialogOpen });
  }

  toggleBatchCamerasDialog = () => {
    let { isBatchCamerasDialogOpen } = this.state;
    this.setState({ isBatchCamerasDialogOpen: !isBatchCamerasDialogOpen });
  }

  handleAssignToGroup = (groupId: string) => {
    const { selectedCameraSerial, addCameraToGroupThunk } = this.props;
    if (!selectedCameraSerial) {
      return;
    }
    addCameraToGroupThunk(selectedCameraSerial, groupId);
    this.setState({ isCameraDialogRequesting: true });
  }

  handleUnassignFromGroup = () => {
    const { selectedCameraSerial, removeCameraFromGroupThunk } = this.props;
    if (!selectedCameraSerial) {
      return;
    }
    removeCameraFromGroupThunk(selectedCameraSerial);
    this.setState({ isCameraDialogRequesting: true });
  }

  handleUpdateCameraMetadata = (externalMetadata: string | undefined) => {
    const { selectedCameraSerial, updateCameraMetadataThunk } = this.props;
    if (!selectedCameraSerial) {
      return;
    }
    this.setState({ isAssetDialogRequesting: true });
    updateCameraMetadataThunk(selectedCameraSerial, externalMetadata);
  }

  render() {
    const { isRequesting, isSuccess, selectedGroupId, selectedCameraMetadata, selectedCameraSerial, filterGroups, dialogGroups, handleSelectGroup, isSelectedCameraInGroup, isFetchingCameras } = this.props;
    const { isCameraDialogOpen, isAssetDialogOpen } = this.state;

    return (
      <div>
        <Card>
          <CardHeader
            title="Cameras"
            subheader={"View cameras by the groups they have been assigned to. The group associated with each camera can also be modified here."}
            titleTypographyProps={{ variant: 'h6' }}
            subheaderTypographyProps={{ variant: 'subtitle2' }}
          />
          <CardContent>
            <div className="camera-table-controls-grid">
              <DropdownSelector
                label="Groups"
                value={selectedGroupId}
                items={filterGroups}
                handleChange={handleSelectGroup}
              />
            </div>
            {
              !isFetchingCameras && (
                <CameraTable 
                  {...this.props} 
                  toggleAssetDialog={this.toggleAssetDialog}
                  toggleCameraDialog={this.toggleCameraDialog}
                />
              )
            }
            {
              isFetchingCameras && (
                <div className="camera-table-spinner">
                  <CircularProgress color="secondary"/>
                </div>
              )
            }
          </CardContent>
        </Card>
        <CameraDialog
          isOpen={isCameraDialogOpen}
          isCameraInGroup={isSelectedCameraInGroup}
          groups={dialogGroups}
          isRequesting={isRequesting}
          isSuccess={isSuccess}
          handleClose={this.toggleCameraDialog}
          handleAssignToGroup={this.handleAssignToGroup}
          handleUnassignFromGroup={this.handleUnassignFromGroup}
        />
        <CameraMetadataDialog
          isOpen={isAssetDialogOpen}
          isRequesting={isRequesting}
          selectedCameraSerial={selectedCameraSerial}
          selectedCameraMetadata={selectedCameraMetadata}
          handleClose={this.toggleAssetDialog}
          handleUpdateCameraMetadata={this.handleUpdateCameraMetadata}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppStateI) => ({
  isRequesting: state.cameras.camera.isRequesting,
  isSuccess: state.cameras.camera.isSuccess,
  isFetchingCameras: getCamerasFetchingState(state),
  isSelectedCameraInGroup: isSelectedCameraInGroup(state),
  selectedCameraSerial: getSelectedCameraSerial(state),
  selectedCameraMetadata: getSelectedCameraMetadata(state),
  selectedGroupId: getSelectedGroupIdForDropdown(state),
  filterGroups: getGroupsForTableFilterDropdown(state),
  dialogGroups: getGroupsForCameraDialogDropdown(state),
  items: getPaginatedCameraTableItems(state),
  count: getCameraTableCount(state),
  page: getCameraTablePage(state),
  rowsPerPage: getCameraTableRowsPerPage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCamerasThunk: getCamerasThunk,
      conditionalGetGroupsThunk: conditionalGetGroupsThunk,
      handleSelect: selectTableItemCameraTable,
      handleUnselectCamera: unselectTableItemCameraTable,
      handleSelectGroup: selectGroupDropdownThunk,
      addCameraToGroupThunk: addCameraToGroupThunk,
      removeCameraFromGroupThunk: removeCameraFromGroupThunk,
      updateCameraMetadataThunk: updateCameraMetadataThunk,
      setPage: setPageCameraTable,
      setRowsPerPage: setRowsPerPageCameraTable,
    },
    dispatch,
  );

type PropsI = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CamerasPage);