// REACT
import React from 'react';

// PROPS
import { UserTablePropsI, UserTableRowHandlers } from 'app/pages/Users.page';
import { PaginatedTableRowPropsI } from 'app/components/PaginatedTableHOC/PaginatedTable.types';
import { PaginatedTable } from 'app/components/PaginatedTableHOC/PaginatedTableHOC';

// APP COMPONENTS
import { UserTableHeader } from './UserTable.header';
import UserTableRow from './UserTable.row';

// MODELS
import { UserTableItem } from 'state/selectors/tables/selector.tables.types';

export type UserTableRowProps = PaginatedTableRowPropsI<UserTableItem> & UserTableRowHandlers;

class UserTableWrapper extends PaginatedTable<UserTableItem> {}

const UserTable: React.FC<UserTablePropsI> = (props) => {
  // console.log("UserTable");
  // console.log(props.items);
  return (
      <UserTableWrapper
        tableKey='cameras'
        title="Users"
        headerComponent={<UserTableHeader />}
        {...props}
        renderRow={(key: string, item: UserTableItem, index: number) => (
          <UserTableRow
            key={key}
            item={item}
            index={index}
            {...props}
          />
        )}
      />
  );
}

export default UserTable;
