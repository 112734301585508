// REACT-REDUX
import React, { Component } from 'react';

// MATERIAL COMPONENTS
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

// APP COMPONENTS
import { GroupI } from 'models/Group/Group.model';
import { Loading } from 'app/components/Loading/Loading';

interface PropsI {
  group: GroupI;
  cameraCount: number;
  isOpen: boolean;
  isRequesting: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

// interface StateI {
//   geotabCompanyId: string;
// }

// FORM FIELDS 
// class TextFieldGeotabCompanyId extends TextFieldSafe<StateI, 'geotabCompanyId'> {}

export default class GroupDeleteDialog extends Component<PropsI> {

  // state: StateI = {
  //   geotabCompanyId: "",
  // };

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState({
      geotabCompanyId: ""
    });
    handleClose();
  }

  // handleChangeTextField = (key: keyof StateI, value: string) => {
  //   switch (key) {
  //     case 'geotabCompanyId': 
  //       this.setState({ geotabCompanyId: value });
  //       break;
  //     default:
  //       return;
  //   }
  // }

  render() {
    const { isOpen, isRequesting, group, cameraCount, handleClose, handleConfirm } = this.props;

    return (
      <Dialog open={isOpen} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">Confirm Delete Group</DialogTitle>
        <DialogContent className="group-dialog-grid">
        {!isRequesting && (
          <div style={{gridColumn: '1 / -1'}}>
            <Typography variant="subtitle1">Group: {group.name}</Typography>
            {cameraCount > 0 ? ( 
              <React.Fragment>
                <Typography variant="body1"><span style={{fontSize: '1.25rem', fontWeight: 500 }}>Warning:</span> This group currently has <span style={{fontSize: '1.25rem', fontWeight: 500 }}>{cameraCount}</span> cameras associated with it.</Typography>
                <Typography variant="body1">Deleting a group is a permanent operation that cannot be undone</Typography>
                <Typography variant="body1">Please confirm that you want to delete this group</Typography>
              </React.Fragment>
            ) : ( 
              <Typography variant="body1">Deleting a group is a permanent operation that cannot be undone. Please confirm that you want to delete this group</Typography>
            )}
          </div>
          )}
          {isRequesting && (
            <div style={{gridColumn: '1 / -1'}}>
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!isRequesting && (
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
          )}
          <Button onClick={handleConfirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}