// REACT-REDUX
import React, { Component } from 'react';

// MATERIAL COMPONENTS
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// APP COMPONENTS
import { TextFieldSafe } from 'app/components/Forms/TextField';

// PROPS
import { CameraMetadataDialogProps } from 'app/pages/Cameras.page';

// APP COMPONENTS
interface StateI {
  _serial: undefined | string;
  externalMetadata: string;
}

class TextFieldMetadata extends TextFieldSafe<StateI, 'externalMetadata'> {}

// const UNASSIGN_TEXT = 'Unassigning a camera from a group will immediately remove the specified camera from that group. Unassigned cameras can be assigned to new group.'
// const ASSIGN_TEXT = 'Assigning a camera to a group will allow that group to view, activate and use the specified camera. Cameras can only be assigned to one group at a time.'

export default class CameraMetadataDialog extends Component<CameraMetadataDialogProps, StateI> {

  state: StateI = {
    _serial: undefined,
    externalMetadata: "",
  }

  static getDerivedStateFromProps(props: CameraMetadataDialogProps, state: StateI) {
    if (props.selectedCameraSerial !== state._serial) {
      let externalMetadata = props.selectedCameraMetadata || "";
      return {
        _serial: props.selectedCameraSerial,
        externalMetadata
      }
    } else {
      return null;
    }
  } 

  handleChange = (_: string, externalMetadata: string) => {
    console.log('handleChange', externalMetadata);
    this.setState({ externalMetadata });
  }

  handleClick = () => {
    const { handleUpdateCameraMetadata, selectedCameraMetadata } = this.props;
    const { externalMetadata } = this.state;
    if (selectedCameraMetadata === externalMetadata) {
      handleUpdateCameraMetadata(undefined);
    } else {
      handleUpdateCameraMetadata(externalMetadata);
    }
    this.setState({ externalMetadata: "" });
  }

  handleClose = () => {
    this.setState({ externalMetadata: "" });
    this.props.handleClose();
  }

  render() {
    const { isOpen, isRequesting, selectedCameraMetadata } = this.props;
    const { externalMetadata, } = this.state;
    const hasExternalMetadata = externalMetadata === selectedCameraMetadata;
    const buttonLabel = hasExternalMetadata ? 'Remove' : 'Update';
    const isButtonDisabled = !hasExternalMetadata && externalMetadata === "";
    return (
      <Dialog open={isOpen} maxWidth={"md"} fullWidth={true} onClose={this.handleClose}>
        <DialogTitle id="max-width-dialog-title">{'Update Camera Metadata'}</DialogTitle>
        <DialogContent>
          <div style={{width: '100%', display: 'grid', gridTemplateColumns: '1fr' }}>
            <Typography variant="body1" style={{marginBottom: 12 }}>{'Set the camera metadata'}</Typography>
            {isRequesting && (
              <CircularProgress color="primary" />
            )}  
            {!isRequesting && (
              <TextFieldMetadata
                label={'Camera Metadata'}
                value={externalMetadata}
                propertyKey={'externalMetadata'}
                handleChange={this.handleChange}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleClick} color="secondary" disabled={isButtonDisabled}>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}