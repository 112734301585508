// MATERIAL STYLES
import { createMuiTheme } from '@material-ui/core/styles';
// import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

export const OwlDarkTheme = createMuiTheme({
  palette: {
    // type: 'light',
    type: 'dark',
    primary: {
      light: green[500],
      main: green[700],
      dark: green[900],
      // contrastText: getContrastText(palette.primary[500]),
    },
    secondary: {
      light: green[200],
      main: green[400],
      dark: green[600],
      // contrastText: getContrastText(palette.secondary.A400),
    },
  }
});

export const OwlLightTheme = createMuiTheme({
  palette: {
    // type: 'light',
    type: 'light',
    primary: {
      light: green[500],
      main: green[700],
      dark: green[900],
      // contrastText: getContrastText(palette.primary[500]),
    },
    secondary: {
      light: green[200],
      main: green[500],
      dark: green[800],
      // contrastText: getContrastText(palette.secondary.A400),
    },
  }
});
