// ENUM
import { PartnerExternalProviderEnumArray } from './Partner.model';

export const PartnerSchema = {
  type: 'object',
  properties: {
    "partnerPoolId": { "type": "string" },
    "externalProvider": { "type": "string", "enum": PartnerExternalProviderEnumArray },
    "allocatedCameras": { "type": "number" },
    "activatedCameras": { "type": "number" }
  },
  required: [ 'partnerPoolId', 'allocatedCameras', 'activatedCameras' ],
};