// REDUX TYPES
import { createAction, ActionsUnionT } from "state/modules/module.actions";

// MODULE TYPES
import { AuthActionType } from "./auth.types";


// BASIC ACTION CREATORS

// GET CAMERAS
export const signInRequestActionCreator = () => createAction(AuthActionType.SIGN_IN_REQUEST);
export type signInRequestActionT = ReturnType<typeof signInRequestActionCreator>;

export const signInSuccessActionCreator = (userId: string) => createAction(AuthActionType.SIGN_IN_SUCCESS, { userId });
export type signInSuccessActionT = ReturnType<typeof signInSuccessActionCreator>;

export const signInFailureActionCreator = (errorMessage: string) => createAction(AuthActionType.SIGN_IN_FAILURE, { errorMessage });
export type signInFailureActionT = ReturnType<typeof signInFailureActionCreator>;

// ADD CAMERA TO GROUP
export const signOutRequestActionCreator = () => createAction(AuthActionType.SIGN_OUT_REQUEST);
export type signOutRequestActionT = ReturnType<typeof signOutRequestActionCreator>;

export const signOutSuccessActionCreator = () => createAction(AuthActionType.SIGN_OUT_SUCCESS);
export type signOutSuccessActionT = ReturnType<typeof signOutSuccessActionCreator>;

export const signOutFailureActionCreator = (errorMessage: string) => createAction(AuthActionType.SIGN_OUT_FAILURE, { errorMessage });
export type signOutFailureActionT = ReturnType<typeof signOutFailureActionCreator>;

export const AuthActionCreators = {
  signInRequest: signInRequestActionCreator,
  signInSuccess: signInSuccessActionCreator,
  signInFailure: signInFailureActionCreator,
  signOutRequest: signOutRequestActionCreator,
  signOutSuccess: signOutSuccessActionCreator,
  signOutFailure: signOutFailureActionCreator,
};

export type AuthActionsUnion = ActionsUnionT<typeof AuthActionCreators>;
