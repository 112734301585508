// RESELECT
import { createSelector } from 'reselect';

// APP STATE
import { AppStateI } from 'state/modules/root.reducer';

// MODULE
import { ThemeMode } from 'state/modules/theme/theme.reducer';

// BASIC SELECTORS
export const getTheme = (state: AppStateI) => state.theme.theme;

// COMPOSED SELECTORS
export const isThemeDarkSelector = createSelector(
  [getTheme],
  (theme) => {
    let isThemeDark = false;
    if (theme === ThemeMode.DARK) {
      isThemeDark = true;
    }
    return isThemeDark;
  },
);
