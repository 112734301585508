// RESELECT
import { createSelector } from "reselect";

// APP STATE
import { AppStateI } from "state/modules/root.reducer";
import { getCamerasArray } from "../modules/cameras.selectors";
import { CameraTableItem } from "./selector.tables.types";
import { getGroupIdToNameMap, GROUP_ALL } from "../modules/groups.selectors";
import { CAMERA_DEFAULT_GROUP } from "models/Camera/Camera.model";
import { getGroupTableSelectedItemId } from "./tables.group.selectors";
import { getCameraEntities } from "../modules/entities.selectors";
import { cameraHasGroupIdTypeGuard } from "models/Camera/Camera.guard";

export const getCameraTablePage = (state: AppStateI) => state.tables.camera.page;
export const getCameraTableRowsPerPage = (state: AppStateI) => state.tables.camera.rowsPerPage;
export const getSelectedCameraSerial = (state: AppStateI) => state.tables.camera.selectedItemId;
export const getCameraTableSelectedItemId = (state: AppStateI) => state.tables.camera.selectedItemId;

export function selectedCameraSerialTypeGuard(selectedCameraSerial: string | undefined): selectedCameraSerial is string {
  if (selectedCameraSerial && typeof selectedCameraSerial === 'string') {
    return true;
  }
  return false;
}

export const getIsCameraSelected = createSelector(
  [getCameraTableSelectedItemId],
  (selectedItemId) => {
    if (selectedCameraSerialTypeGuard(selectedItemId)) {
      return true;
    }
    return false;
  }
)

export const getCameraTablePaginationIndexes = createSelector(
  [getCameraTablePage, getCameraTableRowsPerPage],
  (page, rowsPerPage) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    return [ startIndex, endIndex ];
  }
)

export const getCamerasWithGroupName = createSelector(
  [getCamerasArray, getGroupIdToNameMap],
  (cameras, groupNameMap) => {
    return cameras.map((camera) => {
      let groupName = groupNameMap.get(camera.groupId) || CAMERA_DEFAULT_GROUP;
      return {
        ...camera,
        groupName
      }
    });
  }
)

export const getCamerasWithFilter = createSelector(
  [getCamerasWithGroupName, getGroupTableSelectedItemId],
  (cameras, selectedGroupId) => {
    console.log('selectedGroupId', selectedGroupId);
    if (selectedGroupId && selectedGroupId !== GROUP_ALL) {
      return cameras.filter(({ groupId }) => selectedGroupId === groupId);
    }
    return cameras.filter(({ groupId }) => CAMERA_DEFAULT_GROUP === groupId);
  }
)

export const getCameraTableCount = createSelector(
  [getCamerasWithFilter], 
  (items) => {
    return items.length;
  }
)

export const getCamerasByPagination = createSelector(
  [getCamerasWithFilter, getCameraTablePaginationIndexes],
  (cameras, [startIndex, endIndex]) => {
    return cameras.slice(startIndex, endIndex);
  }
)

export const getPaginatedCameraTableItems = createSelector(
  [getCamerasByPagination, getCameraTableSelectedItemId], 
  (cameras, selectedItemId) => {
    let cameraTableItems: CameraTableItem[];
    cameraTableItems = cameras.map((camera) => {
      return {
        ...camera,
        itemId: camera.cameraSerialNumber,
        isSelected: camera.cameraSerialNumber === selectedItemId,
      }
    }); 
    return cameraTableItems;
  }
)


export const isSelectedCameraInGroup = createSelector(
  [getCameraTableSelectedItemId, getCameraEntities],
  (selectedItemId, cameraEntities) => {
    if (!selectedItemId) {
      return false;
    }
    let camera = cameraEntities[selectedItemId];
    if (cameraHasGroupIdTypeGuard(camera)) {
      return camera.groupId !== CAMERA_DEFAULT_GROUP;
    }
    return false;
  }
)

export const getSelectedCameraMetadata = createSelector(
  [getCameraTableSelectedItemId, getCameraEntities],
  (selectedItemId, cameraEntities) => {
    if (!selectedItemId) {
      return undefined;
    }
    let camera = cameraEntities[selectedItemId];
    if (camera && camera.externalMetadata) {
      return camera.externalMetadata;
    }
    return undefined;
  }
)