// REDUX TYPES
import { createAction, ActionsUnionT } from "state/modules/module.actions";

// MODULE TYPES
import { UsersActionType } from "./users.types";
import { NormalizedUsers } from "models/User/User.normalize";

// BASIC ACTION CREATORS

// GET USERS
export const getUsersRequestActionCreator = () => createAction(UsersActionType.GET_USERS_REQUEST);
export type getUsersRequestActionT = ReturnType<typeof getUsersRequestActionCreator>;

export const getUsersSuccessActionCreator = (normalized: NormalizedUsers) => createAction(UsersActionType.GET_USERS_SUCCESS, normalized);
export type getUsersSuccessActionT = ReturnType<typeof getUsersSuccessActionCreator>;

export const getUsersFailureActionCreator = (errorMessage: string) => createAction(UsersActionType.GET_USERS_FAILURE, { errorMessage });
export type getUsersFailureActionT = ReturnType<typeof getUsersFailureActionCreator>;

// CREATE USER 
export const createUserRequestActionCreator = () => createAction(UsersActionType.CREATE_USER_REQUEST);
export type createUserRequestActionT = ReturnType<typeof createUserRequestActionCreator>;

export const createUserSuccessActionCreator = (normalized: NormalizedUsers) => createAction(UsersActionType.CREATE_USER_SUCCESS, normalized);
export type createUserSuccessActionT = ReturnType<typeof createUserSuccessActionCreator>;

export const createUserFailureActionCreator = (errorMessage: string) => createAction(UsersActionType.CREATE_USER_FAILURE, { errorMessage });
export type createUserFailureActionT = ReturnType<typeof createUserFailureActionCreator>;

// CHANGE USER GROUP / UPDATE USER PERMISSION
export const updateUserPermissionRequestActionCreator = (userId: string, permission: string) => createAction(UsersActionType.UPDATE_USER_PERMISSION_REQUEST, { userId, permission });
export type updateUserPermissionRequestActionT = ReturnType<typeof updateUserPermissionRequestActionCreator>;

export const updateUserPermissionSuccessActionCreator = (normalized: NormalizedUsers) => createAction(UsersActionType.UPDATE_USER_PERMISSION_SUCCESS, normalized);
export type updateUserPermissionSuccessActionT = ReturnType<typeof updateUserPermissionSuccessActionCreator>;

export const updateUserPermissionFailureActionCreator = (errorMessage: string) => createAction(UsersActionType.UPDATE_USER_PERMISSION_FAILURE, { errorMessage });
export type updateUserPermissionFailureActionT = ReturnType<typeof updateUserPermissionFailureActionCreator>;

// CHANGE USER GROUP / UPDATE USER PERMISSION
export const deleteUserRequestActionCreator = () => createAction(UsersActionType.DELETE_USER_REQUEST);
export type deleteUserRequestActionT = ReturnType<typeof deleteUserRequestActionCreator>;

export const deleteUserSuccessActionCreator = (userId: string) => createAction(UsersActionType.DELETE_USER_SUCCESS, { userId });
export type deleteUserSuccessActionT = ReturnType<typeof deleteUserSuccessActionCreator>;

export const deleteUserFailureActionCreator = (errorMessage: string) => createAction(UsersActionType.DELETE_USER_FAILURE, { errorMessage });
export type deleteUserFailureActionT = ReturnType<typeof deleteUserFailureActionCreator>;

export const UsersActionCreators = {
  getUsersRequest: getUsersRequestActionCreator,
  getUsersSuccess: getUsersSuccessActionCreator,
  getUsersFailure: getUsersFailureActionCreator,
  createUserRequest: createUserRequestActionCreator,
  createUserSuccess: createUserSuccessActionCreator,
  createUserFailure: createUserFailureActionCreator,
  updateUserPermissionRequest: updateUserPermissionRequestActionCreator,
  updateUserPermissionSuccess: updateUserPermissionSuccessActionCreator,
  updateUserPermissionFailure: updateUserPermissionFailureActionCreator,
  deleteUserRequest: deleteUserRequestActionCreator,
  deleteUserSuccess: deleteUserSuccessActionCreator,
  deleteUserFailure: deleteUserFailureActionCreator,
};

const UserActionCreators = {
  createUserRequest: createUserRequestActionCreator,
  createUserSuccess: createUserSuccessActionCreator,
  createUserFailure: createUserFailureActionCreator,
  updateUserPermissionRequest: updateUserPermissionRequestActionCreator,
  updateUserPermissionSuccess: updateUserPermissionSuccessActionCreator,
  updateUserPermissionFailure: updateUserPermissionFailureActionCreator,
  deleteUserRequest: deleteUserRequestActionCreator,
  deleteUserSuccess: deleteUserSuccessActionCreator,
  deleteUserFailure: deleteUserFailureActionCreator,
}

export type UsersActionsUnion = ActionsUnionT<typeof UsersActionCreators>;

export type UserActionsUnion = ActionsUnionT<typeof UserActionCreators>;

export type UserEntitiesActions = getUsersSuccessActionT | createUserSuccessActionT;