// REACT-REDUX
import React, { Component } from 'react';

// MODELS
import { SetGroupGeotabCompanyIdParamsI } from 'state/modules/groups/groups.thunks';

// MATERIAL COMPONENTS
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

// APP COMPONENTS
import { Loading } from 'app/components/Loading/Loading';
import { TextFieldSafe } from 'app/components/Forms/TextField';

interface PropsI {
  groupId: string;
  isOpen: boolean;
  isRequesting: boolean;
  handleUpdateGroup: (group: SetGroupGeotabCompanyIdParamsI) => void;
  handleClose: () => void;
}

interface StateI {
  geotabCompanyId: string;
}

// FORM FIELDS 
class TextFieldGeotabCompanyId extends TextFieldSafe<StateI, 'geotabCompanyId'> {}

export default class GroupExternalProviderDialog extends Component<PropsI, StateI> {

  state: StateI = {
    geotabCompanyId: "",
  };

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState({
      geotabCompanyId: ""
    });
    handleClose();
  }

  handleChangeTextField = (key: keyof StateI, value: string) => {
    switch (key) {
      case 'geotabCompanyId': 
        this.setState({ geotabCompanyId: value });
        break;
      default:
        return;
    }
  }

  handleUpdateGroup = () => {
    const { groupId, handleUpdateGroup } = this.props;
    const { geotabCompanyId } = this.state; 
    
    const params = {
      groupId,
      geotabCompanyId,
    }

    handleUpdateGroup(params);
  }

  render() {
    const { isOpen, isRequesting, handleClose } = this.props;
    const { geotabCompanyId } = this.state;
    return (
      <Dialog open={isOpen} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">Set Group Geotab Company Id</DialogTitle>
        <DialogContent className="group-dialog-grid">
          {!isRequesting && (
            <TextFieldGeotabCompanyId
              value={this.state.geotabCompanyId}
              propertyKey={'geotabCompanyId'}
              label={'Company Id'}
              className="group-dialog-geotabCompanyId"
              handleChange={this.handleChangeTextField}
            />
          )}
          {isRequesting && (
            <div style={{gridColumn: '1 / -1'}}>
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!isRequesting && (
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
          )}
          <Button onClick={this.handleUpdateGroup} color="secondary">
            {geotabCompanyId.length > 0 ? "Save" : "Remove" }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}