// REDUX TYPES
import { createAction, ActionsUnionT } from "state/modules/module.actions";

// MODULE TYPES
import { CamerasActionType } from "./cameras.types";

// MODELS
import { NormalizedCameras } from "models/Camera/Camera.normalize";

// BASIC ACTION CREATORS

// GET CAMERAS
export const getCamerasRequestActionCreator = () => createAction(CamerasActionType.GET_CAMERAS_REQUEST);
export type getCamerasRequestActionT = ReturnType<typeof getCamerasRequestActionCreator>;

export const getCamerasSuccessActionCreator = (normalized: NormalizedCameras) => createAction(CamerasActionType.GET_CAMERAS_SUCCESS, normalized);
export type getCamerasSuccessActionT = ReturnType<typeof getCamerasSuccessActionCreator>;

export const getCamerasFailureActionCreator = (errorMessage: string) => createAction(CamerasActionType.GET_CAMERAS_FAILURE, { errorMessage });
export type getCamerasFailureActionT = ReturnType<typeof getCamerasFailureActionCreator>;

// ADD CAMERA TO GROUP
export const addCameraToGroupRequestActionCreator = (cameraSerialNumber: string, groupId: string) => createAction(CamerasActionType.ADD_CAMERA_REQUEST, { cameraSerialNumber, groupId });
export type addCameraToGroupRequestActionT = ReturnType<typeof addCameraToGroupRequestActionCreator>;

export const addCameraToGroupSuccessActionCreator = (normalized: NormalizedCameras) => createAction(CamerasActionType.ADD_CAMERA_SUCCESS, normalized);
export type addCameraToGroupSuccessActionT = ReturnType<typeof addCameraToGroupSuccessActionCreator>;

export const addCameraToGroupFailureActionCreator = (errorMessage: string) => createAction(CamerasActionType.ADD_CAMERA_FAILURE, { errorMessage });
export type addCameraToGroupFailureActionT = ReturnType<typeof addCameraToGroupFailureActionCreator>;

// REMOVE FROM GROUP
export const removeCameraFromGroupRequestActionCreator = (cameraSerialNumber: string) => createAction(CamerasActionType.REMOVE_CAMERA_REQUEST, { cameraSerialNumber });
export type removeCameraFromGroupRequestActionT = ReturnType<typeof removeCameraFromGroupRequestActionCreator>;

export const removeCameraFromGroupSuccessActionCreator = (normalized: NormalizedCameras) => createAction(CamerasActionType.REMOVE_CAMERA_SUCCESS, normalized);
export type removeCameraFromGroupSuccessActionT = ReturnType<typeof removeCameraFromGroupSuccessActionCreator>;

export const removeCameraFromGroupFailureActionCreator = (errorMessage: string) => createAction(CamerasActionType.REMOVE_CAMERA_FAILURE, { errorMessage });
export type removeCameraFromGroupFailureActionT = ReturnType<typeof removeCameraFromGroupFailureActionCreator>;

// REMOVE FROM GROUP
export const updateCameraMetadataRequestActionCreator = (cameraSerialNumber: string, externalMetadata: string | undefined) => createAction(CamerasActionType.UPDATE_CAMERA_METADATA_REQUEST, { cameraSerialNumber, externalMetadata });
export type updateCameraMetadataRequestActionT = ReturnType<typeof updateCameraMetadataRequestActionCreator>;

export const updateCameraMetadataSuccessActionCreator = (normalized: NormalizedCameras) => createAction(CamerasActionType.UPDATE_CAMERA_METADATA_SUCCESS, normalized);
export type updateCameraMetadataSuccessActionT = ReturnType<typeof updateCameraMetadataSuccessActionCreator>;

export const updateCameraMetadataFailureActionCreator = (errorMessage: string) => createAction(CamerasActionType.UPDATE_CAMERA_METADATA_FAILURE, { errorMessage });
export type updateCameraMetadataFailureActionT = ReturnType<typeof updateCameraMetadataFailureActionCreator>;


export const CamerasActionCreators = {
  getCamerasRequest: getCamerasRequestActionCreator,
  getCamerasSuccess: getCamerasSuccessActionCreator,
  getCamerasFailure: getCamerasFailureActionCreator,
  addCameraToGroupRequest: addCameraToGroupRequestActionCreator,
  addCameraToGroupSuccess: addCameraToGroupSuccessActionCreator,
  addCameraToGroupFailure: addCameraToGroupFailureActionCreator,
  removeCameraFromGroupRequest: removeCameraFromGroupRequestActionCreator,
  removeCameraFromGroupSuccess: removeCameraFromGroupSuccessActionCreator,
  removeCameraFromGroupFailure: removeCameraFromGroupFailureActionCreator,
  updateCameraMetadataRequest: updateCameraMetadataRequestActionCreator,
  updateCameraMetadataSuccess: updateCameraMetadataSuccessActionCreator,
  updateCameraMetadataFailure: updateCameraMetadataFailureActionCreator,
};

const CameraActionCreators = {
  addCameraToGroupRequest: addCameraToGroupRequestActionCreator,
  addCameraToGroupSuccess: addCameraToGroupSuccessActionCreator,
  addCameraToGroupFailure: addCameraToGroupFailureActionCreator,
  removeCameraFromGroupRequest: removeCameraFromGroupRequestActionCreator,
  removeCameraFromGroupSuccess: removeCameraFromGroupSuccessActionCreator,
  removeCameraFromGroupFailure: removeCameraFromGroupFailureActionCreator,
  updateCameraMetadataRequest: updateCameraMetadataRequestActionCreator,
  updateCameraMetadataSuccess: updateCameraMetadataSuccessActionCreator,
  updateCameraMetadataFailure: updateCameraMetadataFailureActionCreator,
}

export type CamerasActionsUnion = ActionsUnionT<typeof CamerasActionCreators>;

export type CameraActionsUnion = ActionsUnionT<typeof CameraActionCreators>;

export type CameraEntitiesActions = getCamerasSuccessActionT | addCameraToGroupSuccessActionT | removeCameraFromGroupSuccessActionT | updateCameraMetadataSuccessActionT;