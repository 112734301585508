// REDUX
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// MODULE TYPES
import { AppStateI } from 'state/modules/root.reducer';
// import { GetState } from 'state/modules/module.reducers';

// ACTIONS
import { PartnerActionCreators } from './partner.actions';

// SERVICE - COGNITO
import { getAuthorizedUserPartnerPoolId } from 'services/cognito/cognito.service';

// SERVICE - API
import { getPartnerFromService } from 'services/gateway/modules/partner.service';

// VALIDATED RESPONSE
import { PartnerI } from 'models/Partner/Partner.model';


export const getPartnerThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('getPartnerThunk');
  let partnerPoolId: string | undefined;
  let validatedPartner: PartnerI;
  try {
    partnerPoolId = await getAuthorizedUserPartnerPoolId();
    console.log("partnerPoolId", partnerPoolId);
    if (!partnerPoolId) {
      return;
    }
    dispatch(PartnerActionCreators.getPartnerRequest(partnerPoolId));
    validatedPartner = await getPartnerFromService(partnerPoolId);
    dispatch(PartnerActionCreators.getPartnerSuccess(validatedPartner));
  } catch (err) {
    dispatch(PartnerActionCreators.getPartnerFailure(err.name));
  }
};