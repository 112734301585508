import { AsyncItemsStateI, AsyncRequestStateI } from "state/modules/module.reducers";

export enum UsersActionType {
  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE = 'CREATE_USER_FAILURE',
  UPDATE_USER_PERMISSION_REQUEST = 'UPDATE_USER_PERMISSION_REQUEST',
  UPDATE_USER_PERMISSION_SUCCESS = 'UPDATE_USER_PERMISSION_SUCCESS',
  UPDATE_USER_PERMISSION_FAILURE = 'UPDATE_USER_PERMISSION_FAILURE',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
}

interface UserRequestStateI {
  user: AsyncRequestStateI;
}

export type UsersReducerStateI = AsyncItemsStateI<string> & UserRequestStateI;