// RESELECT
import { createSelector } from "reselect";

// APP STATE
import { AppStateI } from "state/modules/root.reducer";

// SELECTORS
import { getGroupsArray } from "../modules/groups.selectors";
import { GroupTableItem } from "./selector.tables.types";

// MODELS
import { CAMERA_DEFAULT_GROUP } from "models/Camera/Camera.model";
import { getGroupEntities } from "../modules/entities.selectors";

export const getGroupTablePage = (state: AppStateI) => state.tables.group.page;
export const getGroupTableRowsPerPage = (state: AppStateI) => state.tables.group.rowsPerPage;
export const getGroupTableSelectedItemId = (state: AppStateI) => state.tables.group.selectedItemId;

export function selectedGroupTypeGuard(selectedGroup: string | undefined): selectedGroup is string {
  if (selectedGroup && typeof selectedGroup === 'string') {
    return true;
  }
  return false;
}

export const getIsGroupSelected = createSelector(
  [getGroupTableSelectedItemId],
  (selectedItemId) => {
    if (selectedGroupTypeGuard(selectedItemId)) {
      return true;
    }
    return false;
  }
)

export const getSelectedGroupIdForDropdown = createSelector(
  [getGroupTableSelectedItemId],
  (selectedItemId) => {
    if (selectedGroupTypeGuard(selectedItemId)) {
      return selectedItemId;
    }
    return CAMERA_DEFAULT_GROUP;
  }
)

export const getSelectedGroup = createSelector(
  [getGroupTableSelectedItemId, getGroupEntities],
  (selectedItemId, entities) => {
    if (selectedGroupTypeGuard(selectedItemId)) {
      const selectedGroup = entities[selectedItemId];
      console.log("selectedGroup");
      console.log(selectedGroup);
      return selectedGroup;
    }
    return;
  }
)

export const getGroupTableCount = createSelector(
  [getGroupsArray], 
  (items) => {
    return items.length;
  }
)

export const getGroupTablePaginationIndexes = createSelector(
  [getGroupTablePage, getGroupTableRowsPerPage],
  (page, rowsPerPage) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    return [ startIndex, endIndex ];
  }
)

export const getGroupsByPagination = createSelector(
  [getGroupTablePaginationIndexes, getGroupsArray],
  ([startIndex, endIndex], groups) => {
    return groups.slice(startIndex, endIndex);
  }
)

export const getPaginatedGroupTableItems = createSelector(
  [getGroupsByPagination, getGroupTableSelectedItemId], 
  (groups, selectedItemId) => {
    let groupTableItems: GroupTableItem[];
    groupTableItems = groups.map((group) => {
      return {
        ...group,
        itemId: group.groupId,
        isSelected: group.groupId === selectedItemId,
      }
    }); 
    return groupTableItems;
  }
)
