// REACT-REDUX
import React, { Component } from 'react';

// MATERIAL COMPONENTS
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { DropdownSelector, DropdownSelectorItemI } from 'app/components/Selector/DropdownSelector';
import CircularProgress from '@material-ui/core/CircularProgress';

// APP COMPONENTS
interface StateI {
  groupId: string;
}

interface PropsI {
  isOpen: boolean;
  isRequesting: boolean;
  isSuccess: boolean;
  isCameraInGroup: boolean;
  groups: DropdownSelectorItemI[];
  handleClose: () => void;
  handleAssignToGroup: (groupId: string) => void;
  handleUnassignFromGroup: () => void;
}

const UNASSIGN_TEXT = 'Unassigning a camera from a group will immediately remove the specified camera from that group. Unassigned cameras can be assigned to new group.'
const ASSIGN_TEXT = 'Assigning a camera to a group will allow that group to view, activate and use the specified camera. Cameras can only be assigned to one group at a time.'

export default class CameraDialog extends Component<PropsI, StateI> {

  state: StateI = {
    groupId: "",
  }

  handleChangeGroupId = (groupId: string) => {
    this.setState({ groupId });
  }

  handleClick = () => {
    const { isCameraInGroup, handleAssignToGroup, handleUnassignFromGroup} = this.props;
    const { groupId } = this.state;
    if (isCameraInGroup) {
      handleUnassignFromGroup();
    } else {
      handleAssignToGroup(groupId)
    }
  }

  render() {
    const { isOpen, isRequesting, isCameraInGroup, groups, handleClose } = this.props;
    const { groupId, } = this.state;
    const title = isCameraInGroup ? 'Unassign from group' : 'Assign to group';
    const text = isCameraInGroup ? UNASSIGN_TEXT : ASSIGN_TEXT;
    const buttonLabel = isCameraInGroup ? 'Unassign' : 'Assign';
    const isButtonDisabled = !isCameraInGroup && groupId === "";
    return (
      <Dialog open={isOpen} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{text}</Typography>
          {isRequesting && (
            <CircularProgress color="primary" />
          )}  
          {!isCameraInGroup && !isRequesting && (
            <DropdownSelector
              label="Groups"
              value={groupId}
              items={groups}
              placeholderItem={'Select Group'}
              handleChange={this.handleChangeGroupId}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleClick} color="secondary" disabled={isButtonDisabled}>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}