// REDUX
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// MODULE TYPES
import { AppStateI } from 'state/modules/root.reducer';
// import { GetState } from 'state/modules/module.reducers';

// ACTIONS
import { AuthActionCreators } from './auth.actions';
import { signOutWithAmplify, getAuthorizedUserId } from 'services/cognito/cognito.service';


// SELECTORS
// import { shouldFetchAuthSelector } from 'state/selectors/modules/camera.selectors';

export const signInThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('signInThunk');
  dispatch(AuthActionCreators.signInRequest());
  try {
    const userId = await getAuthorizedUserId();
    console.log('userId', userId);
    dispatch(AuthActionCreators.signInSuccess(userId));
  } catch (err) {
    dispatch(AuthActionCreators.signInFailure(err.name));
  }
};


export const signOutThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('signOutThunk');
  dispatch(AuthActionCreators.signOutRequest());

  try {
    const response = await signOutWithAmplify();
    console.log('response');
    console.log(response);
    dispatch(AuthActionCreators.signOutSuccess());
  } catch (err) {
    dispatch(AuthActionCreators.signOutFailure(err.name));
  }
};
