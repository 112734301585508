// REACT
import React from 'react';

// PROPS
import { PaginatedTableRowPropsI, PaginatedTableReduxI } from 'app/components/PaginatedTableHOC/PaginatedTable.types';
import { PaginatedTable } from 'app/components/PaginatedTableHOC/PaginatedTableHOC';

// APP COMPONENTS
import { CameraTableHeader } from './CameraTable.header';
import CameraTableRow from './CameraTable.row';

// MODELS
import { CameraTableItem } from 'state/selectors/tables/selector.tables.types';
import { CameraTableHandlerPropsI } from 'app/pages/Cameras.page';

export type CameraTableRowProps = PaginatedTableRowPropsI<CameraTableItem> & CameraTableHandlerPropsI;

class CameraTableWrapper extends PaginatedTable<CameraTableItem> {}

const CameraTable: React.FC<PaginatedTableReduxI<CameraTableItem> & CameraTableHandlerPropsI> = (props) => {
  // console.log("CameraTable");
  // console.log(props.items);
  return (
      <CameraTableWrapper
        tableKey='cameras'
        title="Cameras"
        headerComponent={<CameraTableHeader />}
        {...props}
        renderRow={(key: string, item: CameraTableItem, index: number) => (
          <CameraTableRow
            key={key}
            item={item}
            index={index}
            handleSelect={props.handleSelect}
            toggleCameraDialog={props.toggleCameraDialog}
            toggleAssetDialog={props.toggleAssetDialog}
          />
        )}
      />
  );
}

export default CameraTable;
