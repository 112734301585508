
export enum OwlPartnerPoolUserPermission {
  MANAGER = "manager",
  ADMIN = "admin"
}

export const UserPermissionEnumForSchema = [
  OwlPartnerPoolUserPermission.MANAGER,
  OwlPartnerPoolUserPermission.ADMIN,
];

export interface UserI {
  partnerPoolId: string;
  userId: string;
  dateCreated: number;
  dateLastModified: number;
  modifiedByUserId: string;
  createdByUserId: string;
  permission: OwlPartnerPoolUserPermission;
  firstName: string;
  lastName: string;
  confirmed: boolean;
  email: string;
}

export type UserAdmin = UserI & { permission: OwlPartnerPoolUserPermission.ADMIN };

export type UserCreateParams = Pick<UserI, 'firstName' |'lastName' | 'email' | 'permission'>;