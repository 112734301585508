// GATEWAY
import { GatewayPathsPCP, getGatewayPCPInvokeUrl } from "../gateway.config";
import { GatewayServiceResponseArrayI, GatewayServiceResponseI } from "../gateway.types";
import { getResourcesFromService, fetchWithAuthentication, postRequestToService, postRequestToServiceWithoutResponse } from "../gateway.service";

// MODELS
import { UserI, OwlPartnerPoolUserPermission, UserCreateParams } from "models/User/User.model";
import { NormalizedUsers, getNormalizedUsersFromArray } from "models/User/User.normalize";
import { getValidatedUserArray, getValidatedUser } from "models/User/User.validate";

// RESPONSE TYPE
type UsersResponse = GatewayServiceResponseArrayI<'users'>
type UserResponse = GatewayServiceResponseI<'owlPartnerPoolUser'>

export async function getUsersFromService(): Promise<NormalizedUsers> {
  let usersResponse: UsersResponse;
  let usersValidated: UserI[];
  let usersNormalized: NormalizedUsers;
  try {
    // usersResponse = await getUsersMock();
    usersResponse = await getResourcesFromService<'users'>(GatewayPathsPCP.USERS);
    usersValidated = getValidatedUserArray(usersResponse.users);
    usersNormalized = getNormalizedUsersFromArray(usersValidated);
    return usersNormalized;
  } catch (err) {
    console.log('Error:getUsersFromService');
    console.log(err);
    throw err;
  }
}

export async function updateUserPermissionWithService(userId: string, permission: OwlPartnerPoolUserPermission): Promise<NormalizedUsers> {
  const url = getGatewayPCPInvokeUrl(GatewayPathsPCP.USER_PERMISSION);
  const body = {
    userId,
    permission
  }
  let usersResponse: UsersResponse;
  let usersValidated: UserI[];
  let usersNormalized: NormalizedUsers;
  try {
    const response = await fetchWithAuthentication('POST', url, JSON.stringify(body));
    console.log('response');
    console.log(response);
    // usersResponse = await getUsersMock();
    usersResponse = await getResourcesFromService<'users'>(GatewayPathsPCP.USERS);
    usersValidated = getValidatedUserArray(usersResponse.users);
    usersNormalized = getNormalizedUsersFromArray(usersValidated);
    return usersNormalized;
  } catch (err) {
    console.log('Error:getUsersFromService');
    console.log(err);
    throw err;
  }
}


export async function createUserWithService(user: UserCreateParams): Promise<NormalizedUsers> {
  let userResponse: UserResponse;
  let userValidated: UserI;
  let userNormalized: NormalizedUsers;
  try {
    userResponse = await postRequestToService<'owlPartnerPoolUser'>(GatewayPathsPCP.USER_CREATE, JSON.stringify(user));
    console.log('userResponse');
    console.log(userResponse);
    userValidated = getValidatedUser(userResponse.owlPartnerPoolUser);
    userNormalized = getNormalizedUsersFromArray([userValidated]);
    return userNormalized;
  } catch (err) {
    console.log('Error:getUsersFromService');
    console.log(err);
    throw err;
  }
}


export async function deleteUserWithService(userId: string): Promise<string> {
  try {
    await postRequestToServiceWithoutResponse(GatewayPathsPCP.USER_DELETE, JSON.stringify({ userId }));
    return userId;
  } catch (err) {
    console.log('Error:deleteUserWithService');
    console.log(err);
    throw err;
  }
}

