// NORMALIZR
import { schema, normalize } from 'normalizr';
import { NormalizedResponseFromArray, NormalizedEntities } from 'models/models.normalize';

// MODEL
import { GroupI } from './Group.model';

const groupEntity = new schema.Entity('groups', {}, { idAttribute: (group: GroupI) => group.groupId });

const groupEntityArray = new schema.Array(groupEntity);

export type NormalizedGroups = NormalizedResponseFromArray<'groups', GroupI>;

export function getNormalizedGroupsFromArray(groups: GroupI[]): NormalizedGroups {
  let normalizedGroups: NormalizedResponseFromArray<'groups', GroupI>;
  normalizedGroups = normalize<GroupI, NormalizedEntities<'groups', GroupI>, string[]>(groups, groupEntityArray);
  return normalizedGroups;
}