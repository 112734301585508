// ENUM
// import { GroupStateEnumForSchema } from './Group.model';

// SCHEMA MODEL
import { SchemaArrayI } from 'models/models.validation';

export const GroupSchema = {
  type: 'object',
  properties: {
    "partnerPoolId": { "type": "string" },
    "groupId": { "type": "string" },
    "dateCreated": { "type": "number" },
    "dateModified": { "type": "number" },
    "name": { "type": "string" },
    "address": { "type": "string" },
    "city": { "type": "string" },
    "state": { "type": "string" },
    "country": { "type": "string" },
    "contactPrimaryFirstname": { "type": "string" },
    "contactPrimaryLastname": { "type": "string" },
    "contactEmail": { "type": "string" },
    "contactPhoneNumber": { "type": "string" },
  },
  required: [ 'partnerPoolId', 'groupId', 'name', 'contactEmail' ],
};

export const GroupArraySchema: SchemaArrayI = {
  type: 'array',
  items: GroupSchema,
};














