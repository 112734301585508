// REACT
import React from 'react';

// PROPS
import { UserTableRowProps } from './UserTable';

// MATERIAL COMPONENTS
import { TableRow, TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// APP COMPONENTS
import { ConditionalIcon } from 'app/components/Icons/ConditionalIcon';
import { UserPermissionRadio } from 'app/components/Forms/UserPermissionRadio';
import { OwlPartnerPoolUserPermission } from 'models/User/User.model';

// STYLES 
import 'app/styles/components/buttons.styles.scss';

const UserTableRow: React.FC<UserTableRowProps> = ({ item, isAuthUserAdmin, handleSelect, handleDelete, handleUpdatePermission }) => {
  const handleSelectWithItemId = () => {
    console.log('handleSelectWithItemId');
    console.log('item.itemId', item.itemId);
    if (typeof handleSelect === 'function') {
      handleSelect(item.itemId);
    }
  }

  const handleDeleteWithItemId = () => {
    if (typeof handleSelect === 'function') {
      handleSelect(item.itemId);
    }
    handleDelete();
  }

  const handleUpdatePermissionsWithItemId = (permission: OwlPartnerPoolUserPermission) => {
    handleUpdatePermission(item.itemId, permission);  
  }

  return (
    <TableRow
      hover
      onClick={handleSelectWithItemId}
      role="checkbox"
      aria-checked={item.isSelected}
      tabIndex={-1}
      selected={item.isSelected}
    >
      <TableCell component="th" scope="row">
        {item.email}
      </TableCell>
      <TableCell><ConditionalIcon isValid={item.confirmed}/></TableCell>
      <TableCell style={{textTransform: 'capitalize'}}>{item.firstName}</TableCell>
      <TableCell style={{textTransform: 'capitalize'}}>{item.lastName}</TableCell>
      <TableCell>
        <UserPermissionRadio
          value={item.permission}
          isDisabled={!isAuthUserAdmin}
          handleChange={handleUpdatePermissionsWithItemId}
        />
      </TableCell>
      <TableCell>
        <Button onClick={handleDeleteWithItemId} disabled={!isAuthUserAdmin} className="button-delete">
          Delete
        </Button>
      </TableCell>

    </TableRow>
  )
}

export default UserTableRow;
