import { createSelector } from "reselect";
import { AppStateI } from "state/modules/root.reducer";
// import { PartnerI } from "models/Partner/Partner.model";

export const getPartner = (state: AppStateI) => state.partner.item;
export const getPartnerState = (state: AppStateI) => state.partner;
export const getPartnerIsValid = (state: AppStateI) => state.partner.isFetching;
export const getPartnerIsFetching = (state: AppStateI) => state.partner.isValid;

export const getPartnerExternalProvider = createSelector(
  [getPartner], 
  (partner) => {
    if (!partner) {
      return;
    } 
    return partner.externalProvider;
  }
)