export enum TablesActionType {
  SET_PAGE = 'SET_PAGE',
  SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE',
  SELECT_TABLE_ITEM = 'SELECT_TABLE_ITEM',
  UNSELECT_TABLE_ITEM = 'UNSELECT_TABLE_ITEM',
}

export enum TableId {
  CAMERA = 'camera',
  USER = 'user',
  GROUP = 'group'
}

export interface TableStateI {
  tableId: TableId;
  page: number;
  rowsPerPage: number;
  selectedItemId: string | undefined;
}

export interface TablesReducerStateI {
  camera: TableStateI;
  group: TableStateI;
  user: TableStateI;
}
