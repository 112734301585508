// MODEL
import { CameraI, CameraWithId, CameraWithGroupId } from './Camera.model';

// UTIL
import { safeObjectHasProperty } from "utils/typesafe";

export function cameraTypeGuard(camera: CameraI | undefined | null): camera is CameraI {
  if (camera && safeObjectHasProperty(camera, 'cameraSerialNumber'))  {
    return true;
  }
  return false;
}

export function cameraHasIdTypeGuard(camera: CameraI): camera is CameraWithId {
  if (camera.cameraId && typeof camera.cameraId === 'string') {
    return true;
  }
  return false;
}

export function filterCamerasIdTypeGuard(cameras: CameraI[]): CameraWithId[] {
  let cameraWithIdArray: CameraWithId[] = [];
  cameras.forEach((camera) => {
    if (cameraHasIdTypeGuard(camera)) {
      cameraWithIdArray.push(camera);
    }
  });
  return cameraWithIdArray;
}


export function cameraHasGroupIdTypeGuard(camera: CameraI): camera is CameraWithGroupId {
  if (camera.groupId && typeof camera.groupId === 'string') {
    return true;
  }
  return false;
}

export function filterCamerasWithGroupIdTypeGuard(cameras: CameraI[]): CameraWithGroupId[] {
  let cameraWithIdArray: CameraWithGroupId[] = [];
  cameras.forEach((camera) => {
    if (cameraHasGroupIdTypeGuard(camera)) {
      cameraWithIdArray.push(camera);
    }
  });
  return cameraWithIdArray;
}