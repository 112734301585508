// REDUX TYPES
import { createAction, ActionsUnionT } from 'state/modules/module.actions';

// MODULE TYPES
import { TablesActionType, TableId } from './tables.types';

// BASIC ACTION CREATORS
export const setPageActionCreator = (tableId: TableId, page: number) => createAction(TablesActionType.SET_PAGE, { tableId, page });
export type setPageRequestActionT = ReturnType<typeof setPageActionCreator>;

export const setRowsPerPageActionCreator = (tableId: TableId, rowsPerPage: number) => createAction(TablesActionType.SET_ROWS_PER_PAGE, { tableId, rowsPerPage });
export type setRowsPerPageRequestActionT = ReturnType<typeof setRowsPerPageActionCreator>;

export const selectTableItemActionCreator = (tableId: TableId, tableItemId: string | undefined) => createAction(TablesActionType.SELECT_TABLE_ITEM, { tableId, tableItemId });
export type selectTableItemRequestActionT = ReturnType<typeof selectTableItemActionCreator>;

export const unselectTableItemActionCreator = (tableId: TableId) => createAction(TablesActionType.UNSELECT_TABLE_ITEM, { tableId });
export type unselectTableItemRequestActionT = ReturnType<typeof unselectTableItemActionCreator>;

// EXAMPLES
export const setPageCameraTable = (page: number) => setPageActionCreator(TableId.CAMERA, page);
export const setRowsPerPageCameraTable = (rowsPerPage: number) => setRowsPerPageActionCreator(TableId.CAMERA, rowsPerPage);
export const selectTableItemCameraTable = (tableItemId: string) => selectTableItemActionCreator(TableId.CAMERA, tableItemId);
export const unselectTableItemCameraTable = () => unselectTableItemActionCreator(TableId.CAMERA);

export const setPageGroupTable = (page: number) => setPageActionCreator(TableId.GROUP, page);
export const setRowsPerPageGroupTable = (rowsPerPage: number) => setRowsPerPageActionCreator(TableId.GROUP, rowsPerPage);
export const selectTableItemGroupTable = (tableItemId: string) => selectTableItemActionCreator(TableId.GROUP, tableItemId);
export const unselectTableItemGroupTable = () => unselectTableItemActionCreator(TableId.GROUP);

export const setPageUserTable = (page: number) => setPageActionCreator(TableId.USER, page);
export const setRowsPerPageUserTable = (rowsPerPage: number) => setRowsPerPageActionCreator(TableId.USER, rowsPerPage);
export const selectTableItemUserTable = (tableItemId: string) => selectTableItemActionCreator(TableId.USER, tableItemId);
export const unselectTableItemUserTable = () => unselectTableItemActionCreator(TableId.USER);

export const TablesActionCreators = {
  setPage: setPageActionCreator,
  setRowsPerPage: setRowsPerPageActionCreator,
  selectTableItem: selectTableItemActionCreator,
  unselectTableItem: unselectTableItemActionCreator,
};

export type TablesActionsUnion = ActionsUnionT<typeof TablesActionCreators>;