// MODULE TYPES
import { UserActionsUnion } from '../users.actions';
import { UsersActionType, UsersReducerStateI } from '../users.types';

// REDUX UTILS
import { mergeState } from 'state/modules/module.reducers';

export function userReducer(state: UsersReducerStateI['user'], action: UserActionsUnion) {
  switch (action.type) {
    case UsersActionType.CREATE_USER_REQUEST: 
    case UsersActionType.UPDATE_USER_PERMISSION_REQUEST:
    case UsersActionType.DELETE_USER_REQUEST:
      return mergeState(state, {
        request: action.type,
        isRequesting: true,
        isSuccess: false,
      });
    case UsersActionType.UPDATE_USER_PERMISSION_SUCCESS:
    case UsersActionType.DELETE_USER_SUCCESS:
    case UsersActionType.CREATE_USER_SUCCESS: {
      return mergeState(state, {
        isRequesting: false,
        isSuccess: true,
      });
    }
    case UsersActionType.CREATE_USER_FAILURE:
    case UsersActionType.UPDATE_USER_PERMISSION_FAILURE:
    case UsersActionType.DELETE_USER_FAILURE: 
      return mergeState(state, {
        isRequesting: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      });
    default: 
      return state;
  }
} 