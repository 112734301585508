// MODELS
// import { ClipT } from 'models/Clip/Clip.model';
// import { CameraI } from 'models/Camera/Camera.model';
// import { CameraCreditsT } from 'models/Credits/Credits.model';
// import { PartnerGroupCameraI, CameraState } from 'models/PartnerGroupCamera/PartnerGroupCamera.model';
// import { TelemetryI } from 'models/Telemetry/Telemetry.model';

// MODULE TYPES 
import { EntitiesReducerStateI, EntitiesReducerActionUnion } from './entities.types';

// SHARED ACTIONS
import { CamerasActionType } from 'state/modules/cameras/cameras.types';
// import { getCamerasSuccessActionT } from 'state/modules/cameras/cameras.actions';

import { GroupsActionType } from 'state/modules/groups/groups.types';
// import { getGroupsSuccessActionT } from 'state/modules/groups/groups.actions';

import { UsersActionType } from 'state/modules/users/users.types';
// import { getUsersSuccessActionT } from 'state/modules/users/users.actions';

// UTILS
import { mergeState } from 'state/modules/module.reducers';

const initialStateCameraSelected: EntitiesReducerStateI = {
  cameras: {},
  groups: {},
  users: {}
}

const initialStateProd: EntitiesReducerStateI = {
  cameras: {},
  groups: {},
  users: {}
};

let initialState = initialStateProd;
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_MODE === 'camera_selected') {
  initialState = initialStateCameraSelected;
}

export function entitiesReducer(state: EntitiesReducerStateI = initialState, action: EntitiesReducerActionUnion) {
  switch (action.type) {
    case CamerasActionType.GET_CAMERAS_SUCCESS: 
    case CamerasActionType.ADD_CAMERA_SUCCESS:
    case CamerasActionType.REMOVE_CAMERA_SUCCESS: 
    case CamerasActionType.UPDATE_CAMERA_METADATA_SUCCESS: {
      return mergeState(state, {
        cameras: {
          ...state.cameras, 
          ...action.entities.cameras,
        }
      });
    }
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_SUCCESS:
    case GroupsActionType.CREATE_GROUP_SUCCESS:
    case GroupsActionType.GET_GROUPS_SUCCESS: {
      return mergeState(state, {
        groups: {
          ...state.groups, 
          ...action.entities.groups,
        }
      });
    }
    case UsersActionType.GET_USERS_SUCCESS:
    case UsersActionType.CREATE_USER_SUCCESS: {
      return mergeState(state, {
        users: {
          ...state.users, 
          ...action.entities.users,
        }
      });
    }
    default:
      return state;
  }
}
