
export type PartnerExternalProvider = 'geotab';
export const PartnerExternalProviderEnumArray: PartnerExternalProvider[] = [ 'geotab' ];
export type PartnerExternalProviderGeotab = 'geotab'


export interface PartnerI {
  partnerPoolId: string;
  externalProvider?: PartnerExternalProvider;
  activatedCameras: number;
  allocatedCameras: number;
}
