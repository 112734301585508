// react
import React from 'react';

// MATERIAL COMPONENTS
import { TableRow, TableHead, TableCell } from '@material-ui/core';

export const UserTableHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>Verified</TableCell>
        <TableCell>First Name</TableCell>
        <TableCell>Last Name</TableCell>
        <TableCell>Role</TableCell>
        <TableCell></TableCell>
        {/* <TableCell align="left">Last Seen</TableCell> */}
        {/* <TableCell align="left">Time</TableCell> */}
        {/* <TableCell align="left">Location</TableCell> */}
        {/* <TableCell align="left">Credits</TableCell> */}
      </TableRow>
    </TableHead>
  )
}