// REACT-REDUX
import React, { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// APP STATE
import { AppStateI } from 'state/modules/root.reducer';

// ACTIONS
import { conditionalGetGroupsThunk, createGroupThunk, SetGroupGeotabCompanyIdParamsI, setGroupGeotabCompanyIdThunk, deleteGroupThunk } from 'state/modules/groups/groups.thunks';

// SELECTORS
import { getPaginatedGroupTableItems, getGroupTableCount, getGroupTablePage, getGroupTableRowsPerPage, getGroupTableSelectedItemId, getSelectedGroup } from 'state/selectors/tables/tables.group.selectors';
import { selectTableItemGroupTable, unselectTableItemGroupTable, setPageGroupTable, setRowsPerPageGroupTable } from 'state/modules/tables/tables.actions';

// MATERIAL COMPONENTS
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';

// MATERIAL ICONS
import AddIcon from '@material-ui/icons/Add';

// APP COMPONENTS
import GroupTable from 'app/components/Tables/GroupTable/GroupTable';
import GroupDialog from 'app/containers/GroupDialog/GroupDialog';
import { GroupCreateParams } from 'models/Group/Group.model';
import { isAuthUserAdminSelector } from 'state/selectors/modules/auth.selectors';
import { getPartnerExternalProvider } from 'state/selectors/modules/partner.selectors';
import GroupExternalProviderDialog from 'app/containers/GroupDialog/GroupExternalProviderDialog';
import GroupDeleteDialog from 'app/containers/GroupDialog/GroupDeleteDialog';
import { getCameraTableCount } from 'state/selectors/tables/tables.camera.selectors';
import { getCamerasThunk } from 'state/modules/cameras/cameras.thunks';

interface StateI {
  isCreateDialogOpen: boolean;
  isCreateDialogRequesting: boolean;
  isExternalProviderDialogOpen: boolean;
  isExternalProviderDialogRequesting: boolean;
  isDeleteDialogOpen: boolean;
  isDeleteDialogRequesting: boolean;
}

const initialState: StateI = {
  isCreateDialogOpen: false,
  isCreateDialogRequesting: false,
  isExternalProviderDialogOpen: false,
  isExternalProviderDialogRequesting: false,
  isDeleteDialogOpen: false,
  isDeleteDialogRequesting: false,
}

class GroupsPage extends Component<PropsI, StateI> {

  state = initialState;

  componentDidMount = () => {
    const { conditionalGetGroupsThunk } = this.props;
    conditionalGetGroupsThunk();
  }

  static getDerivedStateFromProps (props: PropsI, state: StateI) {
    if (state.isCreateDialogOpen && state.isCreateDialogRequesting && props.isSuccess) {
      return { isCreateDialogOpen: false, isCreateDialogRequesting: false }
    } else if (state.isExternalProviderDialogOpen && state.isExternalProviderDialogRequesting && props.isSuccess) {
      return { isExternalProviderDialogOpen: false, isExternalProviderDialogRequesting: false }
    } else if (state.isDeleteDialogOpen && state.isDeleteDialogRequesting && props.isSuccess) {
      return { isDeleteDialogOpen: false, isDeleteDialogRequesting: false }
    }else {
      return null;
    }
  }

  toggleCreateDialog = () => {
    let { isCreateDialogOpen } = this.state;
    this.setState({ isCreateDialogOpen: !isCreateDialogOpen });
  }

  toggleDeleteDialog = () => {
    console.log('toggleDeleteDialog');
    let { isDeleteDialogOpen } = this.state;
    this.setState({ isDeleteDialogOpen: !isDeleteDialogOpen });
  }

  toggleExternalProviderDialog = () => {
    let { isExternalProviderDialogOpen } = this.state;
    this.setState({ isExternalProviderDialogOpen: !isExternalProviderDialogOpen });
  }

  handleCreateGroup = (group: GroupCreateParams) => {
    const { createGroupThunk } = this.props;
    createGroupThunk(group)
    this.setState({ isCreateDialogRequesting: true });
  }

  handleUpdateGroup = (params: SetGroupGeotabCompanyIdParamsI) => {
    const { setGroupGeotabCompanyIdThunk } = this.props;
    setGroupGeotabCompanyIdThunk(params);
    this.setState({ isExternalProviderDialogRequesting: true });
  }

  handleDeleteGroup = () => {
    const { selectedGroupId, deleteGroupThunk } = this.props;
    if (!selectedGroupId) {
      return;
    }
    deleteGroupThunk(selectedGroupId);
    this.setState({ isDeleteDialogRequesting: true });
  }

  handleDisplayDeleteDialog = (groupId: string) => {
    const { getCamerasThunk, handleSelect } = this.props;
    handleSelect(groupId);
    getCamerasThunk(groupId);
    setTimeout(() => {
      this.toggleDeleteDialog();
    }, 150);
  }

  render() {
    const { isAuthUserAdmin, selectedGroupId, selectedGroup, selectedGroupCameraCount } = this.props;
    const { isCreateDialogOpen, isExternalProviderDialogOpen, isExternalProviderDialogRequesting, isDeleteDialogOpen, isDeleteDialogRequesting } = this.state;
    return (
      <div>
        <Card>
          <CardHeader
            title="Groups"
            subheader={"Change the vehicle information associated with this group"}
            titleTypographyProps={{ variant: 'h6' }}
            subheaderTypographyProps={{ variant: 'subtitle2' }}
            action={
              <Button
                aria-label="create-user" 
                onClick={this.toggleCreateDialog}
                startIcon={<AddIcon />}
                disabled={!isAuthUserAdmin}
              > 
                Create Group
              </Button>
            }
          />
          <CardContent>
            <GroupTable 
              {...this.props} 
              handleDelete={this.handleDisplayDeleteDialog}
              toggleExternalProviderDialog={this.toggleExternalProviderDialog}
            />
          </CardContent>
        </Card>
        <GroupDialog
          isOpen={isCreateDialogOpen}
          handleCreateGroup={this.handleCreateGroup}
          handleClose={this.toggleCreateDialog}
        />
        {selectedGroupId && (
          <GroupExternalProviderDialog
            groupId={selectedGroupId}
            isOpen={isExternalProviderDialogOpen}
            isRequesting={isExternalProviderDialogRequesting}
            handleUpdateGroup={this.handleUpdateGroup}
            handleClose={this.toggleExternalProviderDialog}
          />
        )}
        {selectedGroup && (
          <GroupDeleteDialog
            group={selectedGroup}
            cameraCount={selectedGroupCameraCount}
            isOpen={isDeleteDialogOpen}
            isRequesting={isDeleteDialogRequesting}
            handleConfirm={this.handleDeleteGroup}
            handleClose={this.toggleDeleteDialog}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppStateI) => ({
  isSuccess: state.groups.group.isSuccess,
  isAuthUserAdmin: isAuthUserAdminSelector(state),
  externalProvider: getPartnerExternalProvider(state),
  selectedGroupId: getGroupTableSelectedItemId(state),
  selectedGroup: getSelectedGroup(state),
  selectedGroupCameraCount: getCameraTableCount(state),
  items: getPaginatedGroupTableItems(state),
  count: getGroupTableCount(state),
  page: getGroupTablePage(state),
  rowsPerPage: getGroupTableRowsPerPage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      conditionalGetGroupsThunk: conditionalGetGroupsThunk,
      createGroupThunk: createGroupThunk,
      getCamerasThunk: getCamerasThunk,
      setGroupGeotabCompanyIdThunk: setGroupGeotabCompanyIdThunk,
      deleteGroupThunk: deleteGroupThunk,
      handleSelect: selectTableItemGroupTable,
      handleUnselectGroup: unselectTableItemGroupTable,
      setPage: setPageGroupTable,
      setRowsPerPage: setRowsPerPageGroupTable,
    },
    dispatch,
  );

type PropsI = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface EventHandlerPropsI {
  handleDelete: (itemId: string) => void;
  toggleExternalProviderDialog: () => void;
}

export type GroupTableOwnProps = Pick<PropsI, 'isAuthUserAdmin' | 'externalProvider'> & EventHandlerPropsI;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupsPage);