// MODEL
import { UserI } from './User.model';

// SCHEMA
import { UserSchema, UserArraySchema } from './User.schema';

// VALIDATION UTILS
import { getValidatedTypeFromJson, getValidatedArrayFromJson } from 'models/models.validation';

export function getValidatedUserArray(json: unknown[]): UserI[] {
  // console.log('getValidatedUserBaseArray');
  // console.log('json', typeof json, Array.isArray(json));
  return getValidatedArrayFromJson<UserI>(json, UserArraySchema);
}

export function getValidatedUser(json: unknown): UserI {
  return getValidatedTypeFromJson(json, UserSchema);
}
