// react
import React from 'react';

// MATERIAL COMPONENTS
import { TableRow, TableHead, TableCell } from '@material-ui/core';

export const CameraTableHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={2}>Serial</TableCell>
        <TableCell>State</TableCell>
        <TableCell colSpan={2}>Group</TableCell>
        <TableCell colSpan={2}>Asset</TableCell>
      </TableRow>
    </TableHead>
  )
}