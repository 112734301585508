// NORMALIZR
import { schema, normalize } from 'normalizr';
import { NormalizedResponseFromArray, NormalizedEntities } from 'models/models.normalize';

// MODEL
import { CameraI } from './Camera.model';

const cameraEntity = new schema.Entity('cameras', {}, { idAttribute: (camera: CameraI) => camera.cameraSerialNumber });

const cameraEntityArray = new schema.Array(cameraEntity);

export type NormalizedCameras = NormalizedResponseFromArray<'cameras', CameraI>;

export function getNormalizedCamerasFromArray(cameras: CameraI[]): NormalizedCameras {
  let normalizedCameras: NormalizedResponseFromArray<'cameras', CameraI>;
  normalizedCameras = normalize<CameraI, NormalizedEntities<'cameras', CameraI>, string[]>(cameras, cameraEntityArray);
  return normalizedCameras;
}