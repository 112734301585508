// REACT
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

// AMPLIFY
import Amplify from "aws-amplify";
import { CognitoConfig } from 'services/cognito/cognito.config';

// AMPLIFY REACT
import {
  withAuthenticator,
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  ForgotPassword,
  Loading,
  VerifyContact,
  ConfirmSignUp,
  TOTPSetup
} from "aws-amplify-react";

// APP WITH ROUTER
import RouterProvider from './Router.provider';

// ACTIONS
import { signInThunk } from 'state/modules/auth/auth.thunks';
import { conditionalGetUsersThunk } from 'state/modules/users/users.thunks';
import { getPartnerThunk } from 'state/modules/partner/partner.thunks';

// CONFIGURE AMPLIFY
Amplify.configure(CognitoConfig);

class AuthWrapper extends Component<Props> {
  componentDidMount() {
    console.log('componentDidMount');
    const { signInThunk, conditionalGetUsersThunk, getPartnerThunk } = this.props;
    signInThunk();
    conditionalGetUsersThunk();
    getPartnerThunk();
  }

  render() {
    return (
      <React.Fragment>
        <RouterProvider />
      </React.Fragment>
    )
  }
}

// REDUX MAPPING
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signInThunk: signInThunk,
      conditionalGetUsersThunk: conditionalGetUsersThunk,
      getPartnerThunk: getPartnerThunk
    },
    dispatch,
  );

type Props = ReturnType<typeof mapDispatchToProps>;

const AuthWrapperConnected = connect(
  null,
  mapDispatchToProps,
)(AuthWrapper);

// APP AUTH PROVIDER
export default withAuthenticator(AuthWrapperConnected, {
  authenticatorComponents: [
    <SignIn key={'aws-amplify-SignIn'}/>,
    <ConfirmSignIn key={'aws-amplify-ConfirmSignIn'}/>,
    <RequireNewPassword key={'aws-amplify-RequireNewPassword'}/>,
    <ConfirmSignUp key={'aws-amplify-ConfirmSignUp'}/>,
    <VerifyContact key={'aws-amplify-VerifyContact'}/>,
    <ForgotPassword key={'aws-amplify-ForgotPassword'}/>,
    <TOTPSetup key={'aws-amplify-TOTPSetup'}/>,
    <Loading key={'aws-amplify-Loading'}/>
  ]
});
