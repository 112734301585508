// REDUX
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// MODULE TYPES
import { AppStateI } from 'state/modules/root.reducer';
// import { GetState } from 'state/modules/module.reducers';

// ACTIONS
import { GroupsActionCreators } from './groups.actions';

// SELECTORS
// import { shouldFetchGroupsSelector } from 'state/selectors/modules/camera.selectors';

// SERVICE
import { getGroupsFromService, createGroupWithService, setGroupGeotabCompanyIdWithService, deleteGroupWithService, removeGroupCamerasWithService } from 'services/gateway/modules/groups.service';

// NORMALIZED RESPONSE
import { NormalizedGroups } from 'models/Group/Group.normalize';
import { selectTableItemGroupTable } from '../tables/tables.actions';

// SHARED ACTIONS
import { getCamerasThunk } from '../cameras/cameras.thunks';
import { GroupCreateParams } from 'models/Group/Group.model';
import { GetState } from '../module.reducers';
import { getGroupsValidState } from 'state/selectors/modules/groups.selectors';
import { getCameraTableCount } from 'state/selectors/tables/tables.camera.selectors';

export const getGroupsThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('getGroupsThunk');
  dispatch(GroupsActionCreators.getGroupsRequest());

  let normalizedGroups: NormalizedGroups;
  try {
    normalizedGroups = await getGroupsFromService();
    dispatch(GroupsActionCreators.getGroupsSuccess(normalizedGroups));
  } catch (err) {
    dispatch(GroupsActionCreators.getGroupsFailure(err.name));
  }
};

export const conditionalGetGroupsThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>, getState: GetState) => {
  const state = getState();
  const isValid = getGroupsValidState(state);
  if (!isValid) {
    dispatch(getGroupsThunk());
  }
};

export const selectGroupDropdownThunk = (groupId: string) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('selectGroupDropdownThunk');
  dispatch(getCamerasThunk(groupId));
  dispatch(selectTableItemGroupTable(groupId));
};


export const createGroupThunk = (group: GroupCreateParams) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('createGroupThunk');
  dispatch(GroupsActionCreators.createGroupRequest());

  let normalizedGroups: NormalizedGroups;
  try {
    normalizedGroups = await createGroupWithService(group);
    dispatch(GroupsActionCreators.createGroupSuccess(normalizedGroups));
  } catch (err) {
    dispatch(GroupsActionCreators.createGroupFailure(err.name));
  }
};


// ------------------------------------------------------------
// EXTERNAL PROVIDER THUNKS
// ------------------------------------------------------------
export interface SetGroupGeotabCompanyIdParamsI {
  groupId: string;
  geotabCompanyId: string;
}

export const setGroupGeotabCompanyIdThunk = (group: SetGroupGeotabCompanyIdParamsI) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('setGroupGeotabCompanyIdThunk');
  dispatch(GroupsActionCreators.setGroupGeotabCompanyIdRequest(group));

  let normalizedGroups: NormalizedGroups;
  try {
    normalizedGroups = await setGroupGeotabCompanyIdWithService(group);
    dispatch(GroupsActionCreators.setGroupGeotabCompanyIdSuccess(normalizedGroups));
  } catch (err) {
    dispatch(GroupsActionCreators.setGroupGeotabCompanyIdFailure(err.name));
  }
};

export const deleteGroupThunk = (groupId: string) => async (dispatch: ThunkDispatch<AppStateI, void, Action>, getState: GetState) => {
  console.log('deleteGroupThunk');
  dispatch(GroupsActionCreators.deleteGroupRequest(groupId));

  let state: AppStateI;
  let cameraCount: number;
  try {
    state = getState();
    cameraCount = getCameraTableCount(state);
    if (cameraCount > 0) {
      await removeGroupCamerasWithService(groupId);
    }
    await deleteGroupWithService(groupId);
    dispatch(GroupsActionCreators.deleteGroupSuccess(groupId));
  } catch (err) {
    dispatch(GroupsActionCreators.deleteGroupFailure(err.name));
  }
};