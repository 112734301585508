// REACT
import React from 'react';

// PROPS
import { GroupTableRowProps } from './GroupTable';

// MATERIAL COMPONENTS
import { TableCell, IconButton } from '@material-ui/core';

// MATERIAL ICONS
import CreateIcon from '@material-ui/icons/Create';

// STYLES 
import 'app/styles/components/buttons.styles.scss';

const GroupTableCellExternalProvider: React.FC<Pick<GroupTableRowProps, 'item' | 'externalProvider'> & { handleClick: () => void }> = ({ externalProvider, item, handleClick }) => {
  if (externalProvider === 'geotab') {
    return (
      <React.Fragment>
        <TableCell>
          {item.geotabCompanyId || '-'}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleClick}>
            <CreateIcon />
          </IconButton>
        </TableCell>
      </React.Fragment>
    )
  } else {
    return (
      <TableCell></TableCell>
    )
  }
}

export default GroupTableCellExternalProvider;
