// GATEWAY
import { GatewayPathsPCP } from "../gateway.config";
import { GatewayServiceResponseArrayI } from "../gateway.types";
import { getResourcesFromService, postRequestToService } from "../gateway.service";

// MODELS
import { CameraI } from "models/Camera/Camera.model";
import { NormalizedCameras, getNormalizedCamerasFromArray } from "models/Camera/Camera.normalize";
import { getValidatedCameraArray, getValidatedCamera } from "models/Camera/Camera.validate";

// MOCK
// import { getCamerasMock } from "services/mocks/cameras/cameras.mock";

// RESPONSE TYPE
type CamerasResponse = GatewayServiceResponseArrayI<'cameras'>

export async function getCamerasFromService(groupId?: string): Promise<NormalizedCameras> {
  let camerasResponse: CamerasResponse;
  let camerasValidated: CameraI[];
  let camerasNormalized: NormalizedCameras;
  let body: { groupId: string };
  let bodyString: string | undefined;
  try {
    if (groupId) {
      body = { groupId };
      bodyString = JSON.stringify(body);
    }
    camerasResponse = await getResourcesFromService<'cameras'>(GatewayPathsPCP.CAMERAS, bodyString);
    camerasValidated = getValidatedCameraArray(camerasResponse.cameras);
    camerasNormalized = getNormalizedCamerasFromArray(camerasValidated);
    return camerasNormalized;
  } catch (err) {
    console.log('Error:getCamerasFromService');
    console.log(err);
    throw err;
  }
}

interface UpdateCameraGroupRequestBodyI {
  cameraSerialNumber: string;
  groupId?: string;
}

export async function updateCameraGroupWithService(cameraSerialNumber: string, groupId?: string): Promise<NormalizedCameras> {
  const body: UpdateCameraGroupRequestBodyI = {
    cameraSerialNumber,
  }
  let cameraValidated: CameraI;
  let camerasNormalized: NormalizedCameras;
  try {
    if (groupId) {
      body.groupId = groupId;
    }
    const response = await postRequestToService<'owlPartnerCamera'>(GatewayPathsPCP.CAMERA_GROUP, JSON.stringify(body));
    cameraValidated = getValidatedCamera(response.owlPartnerCamera);
    camerasNormalized = getNormalizedCamerasFromArray([cameraValidated]);
    return camerasNormalized;
  } catch (err) {
    console.log('Error:getUsersFromService');
    console.log(err);
    throw err;
  }
}

interface UpdateCameraMetadataRequestBodyI {
  cameraSerialNumber: string;
  externalMetadata?: string;
}

export async function updateCameraMetadataWithService(cameraSerialNumber: string, externalMetadata: string | undefined): Promise<NormalizedCameras> {
  const body: UpdateCameraMetadataRequestBodyI = {
    cameraSerialNumber,
  }
  let cameraValidated: CameraI;
  let camerasNormalized: NormalizedCameras;
  try {
    if (externalMetadata) {
      body.externalMetadata = externalMetadata;
    }
    console.log("body");
    console.log(body);
    const response = await postRequestToService<'owlPartnerCamera'>(GatewayPathsPCP.CAMERA_METADATA, JSON.stringify(body));
    cameraValidated = getValidatedCamera(response.owlPartnerCamera);
    camerasNormalized = getNormalizedCamerasFromArray([cameraValidated]);
    return camerasNormalized;
  } catch (err) {
    console.log('Error:updateCameraMetadataWithService');
    console.log(err);
    throw err;
  }
}
