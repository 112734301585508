// GATEWAY
import { GatewayPathsPCP } from "../gateway.config";
import { GatewayServiceResponseI } from "../gateway.types";
import { postRequestToService } from "../gateway.service";

// MODELS
import { PartnerI } from "models/Partner/Partner.model";
import { getValidatedPartner } from "models/Partner/Partner.validate";

// RESPONSE TYPE
type PartnerResponse = GatewayServiceResponseI<'partnerPool'>

export async function getPartnerFromService(partnerPoolId: string): Promise<PartnerI> {
  let partnerResponse: PartnerResponse;
  let partnerValidated: PartnerI;
  try {
    partnerResponse = await postRequestToService<'partnerPool'>(GatewayPathsPCP.PARTNER, JSON.stringify({ partnerPoolId }));
    partnerValidated = getValidatedPartner(partnerResponse.partnerPool);
    return partnerValidated;
  } catch (err) {
    console.log('Error:getUsersFromService');
    console.log(err);
    throw err;
  }
}