// REDUX
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// MODULE TYPES
import { AppStateI } from 'state/modules/root.reducer';
// import { GetState } from 'state/modules/module.reducers';

// ACTIONS
import { UsersActionCreators } from './users.actions';

// SELECTORS
// import { shouldFetchUsersSelector } from 'state/selectors/modules/user.selectors';

// SERVICE
import { getUsersFromService, updateUserPermissionWithService, createUserWithService, deleteUserWithService } from 'services/gateway/modules/users.service';

// NORMALIZED RESPONSE
import { NormalizedUsers } from 'models/User/User.normalize';
import { OwlPartnerPoolUserPermission, UserCreateParams } from 'models/User/User.model';
import { GetState } from '../module.reducers';
import { getUsersValidState } from 'state/selectors/modules/users.selectors';

export const getUsersThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('getUsersThunk');
  dispatch(UsersActionCreators.getUsersRequest());

  let normalizedUsers: NormalizedUsers;
  try {
    normalizedUsers = await getUsersFromService();
    dispatch(UsersActionCreators.getUsersSuccess(normalizedUsers));
  } catch (err) {
    dispatch(UsersActionCreators.getUsersFailure(err.name));
  }
};


export const conditionalGetUsersThunk = () => async (dispatch: ThunkDispatch<AppStateI, void, Action>, getState: GetState) => {
  const state = getState();
  const isUsersValid = getUsersValidState(state);
  if (!isUsersValid) {
    dispatch(getUsersThunk());
  }
};


export const updateUserPermissionThunk = (userId: string, permission: OwlPartnerPoolUserPermission) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('getUsersThunk');
  dispatch(UsersActionCreators.updateUserPermissionRequest(userId, permission));

  let normalizedUsers: NormalizedUsers;
  try {
    normalizedUsers = await updateUserPermissionWithService(userId, permission);
    dispatch(UsersActionCreators.getUsersSuccess(normalizedUsers));
  } catch (err) {
    dispatch(UsersActionCreators.getUsersFailure(err.name));
  }
};


export const createUserThunk = (user: UserCreateParams) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('getUsersThunk');
  dispatch(UsersActionCreators.createUserRequest());

  let normalizedUsers: NormalizedUsers;
  try {
    normalizedUsers = await createUserWithService(user);
    dispatch(UsersActionCreators.createUserSuccess(normalizedUsers));
  } catch (err) {
    dispatch(UsersActionCreators.createUserFailure(err.name));
  }
};


export const deleteUserThunk = (userId: string) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('deleteUserThunk');
  dispatch(UsersActionCreators.deleteUserRequest());

  try {
    await deleteUserWithService(userId);
    dispatch(UsersActionCreators.deleteUserSuccess(userId));
  } catch (err) {
    dispatch(UsersActionCreators.deleteUserFailure(err.name));
  }
};