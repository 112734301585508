// MODULE TYPES
import { GroupActionsUnion } from '../groups.actions';
import { GroupsActionType, GroupsReducerStateI } from '../groups.types';

// REDUX UTILS
import { mergeState } from 'state/modules/module.reducers';

export function groupReducer(state: GroupsReducerStateI['group'], action: GroupActionsUnion) {
  switch (action.type) {
    case GroupsActionType.CREATE_GROUP_REQUEST: 
    case GroupsActionType.DELETE_GROUP_REQUEST:
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_REQUEST:
      return mergeState(state, {
        request: action.type,
        isRequesting: true,
        isSuccess: false,
      });
    case GroupsActionType.DELETE_GROUP_SUCCESS:
    case GroupsActionType.CREATE_GROUP_SUCCESS:
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_SUCCESS:
      return mergeState(state, {
        isRequesting: false,
        isSuccess: true,
      });
    case GroupsActionType.CREATE_GROUP_FAILURE:
    case GroupsActionType.DELETE_GROUP_FAILURE:
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_FAILURE: 
      return mergeState(state, {
        isRequesting: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      });
    default: 
      return state;
  }
} 