export const CameraStateEnumForSchema = ['active', 'inactive', 'allocated'];

export enum CameraState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALLOCATED = 'allocated',
}

export const CAMERA_DEFAULT_GROUP = 'unassigned'

export interface CameraI {
  cameraSerialNumber: string;
  partnerPoolId: string;
  cameraState: CameraState;
  cameraId?: string;
  dateLastActivated?: number;
  dateLastInactivated?: number;
  groupId?: string;
  externalMetadata?: string;
}

export interface CameraGroupDataI {
  groupId: string;
  groupName: string;
}

export type CameraWithId = CameraI & { cameraId: string };

export type CameraWithGroupId = CameraI & { groupId: string };

export type CameraWithGroup = CameraI & CameraGroupDataI;
