import { createSelector } from 'reselect';
import { AppStateI } from 'state/modules/root.reducer';

// SELECTORS
import { getUserEntities } from './entities.selectors';
import { userAdminTypeGuard } from 'models/User/User.guard';

export const getAuthUserId = (state: AppStateI) => state.auth.userId;

export const isAuthUserAdminSelector = createSelector(
  [ getAuthUserId, getUserEntities],
  (userId, userEntities) => {
    let isAuthUserAdmin = false;
    if (userId && userAdminTypeGuard(userEntities[userId])) {
      isAuthUserAdmin = true;
    }
    return isAuthUserAdmin;
  }
)
