// SERVICE
import { postRequestToService } from "../gateway.service";

// GATEWAY CONFIG + TYPES
import { GatewayPathsPCP } from "../gateway.config";
import { GatewayServiceResponseArrayI, GatewayServiceResponseI } from "../gateway.types";
import { getResourcesFromService } from "../gateway.service";

// MODELS
import { GroupI, GroupCreateParams } from "models/Group/Group.model";
import { NormalizedGroups, getNormalizedGroupsFromArray } from "models/Group/Group.normalize";
import { getValidatedGroupArray, getValidatedGroup } from "models/Group/Group.validate";
import { SetGroupGeotabCompanyIdParamsI } from "state/modules/groups/groups.thunks";

// RESPONSE TYPE
type GroupsResponse = GatewayServiceResponseArrayI<'groups'>
type GroupResponse = GatewayServiceResponseI<'owlPartnerPoolGroup'>

export async function getGroupsFromService(): Promise<NormalizedGroups> {
  let groupsResponse: GroupsResponse;
  let groupsValidated: GroupI[];
  let groupsNormalized: NormalizedGroups;
  try {
    groupsResponse = await getResourcesFromService<'groups'>(GatewayPathsPCP.GROUPS);
    console.log("groupsResponse");
    console.log(groupsResponse);
    groupsValidated = getValidatedGroupArray(groupsResponse.groups);
    groupsNormalized = getNormalizedGroupsFromArray(groupsValidated);
    return groupsNormalized;
  } catch (err) {
    console.log('Error:getGroupsFromService');
    console.log(err);
    throw err;
  }
}

export async function createGroupWithService(group: GroupCreateParams): Promise<NormalizedGroups> {
  let groupResponse: GroupResponse;
  let groupValidated: GroupI;
  let groupNormalized: NormalizedGroups;
  try {
    groupResponse = await postRequestToService<'owlPartnerPoolGroup'>(GatewayPathsPCP.GROUP_CREATE, JSON.stringify(group));
    console.log('groupResponse');
    console.log(groupResponse);
    groupValidated = getValidatedGroup(groupResponse.owlPartnerPoolGroup);
    groupNormalized = getNormalizedGroupsFromArray([groupValidated]);
    return groupNormalized;
  } catch (err) {
    console.log('Error:createGroupWithService');
    console.log(err);
    throw err;
  }
}

export async function setGroupGeotabCompanyIdWithService(params: SetGroupGeotabCompanyIdParamsI): Promise<NormalizedGroups> {
  let groupResponse: GroupResponse;
  let groupValidated: GroupI;
  let groupNormalized: NormalizedGroups;
  try {
    if (params.geotabCompanyId === '') {
      delete params.geotabCompanyId;
    }
    groupResponse = await postRequestToService<'owlPartnerPoolGroup'>(GatewayPathsPCP.GROUP_SET_GEOTAB_COMPANY_ID, JSON.stringify(params));
    groupValidated = getValidatedGroup(groupResponse.owlPartnerPoolGroup);
    groupNormalized = getNormalizedGroupsFromArray([groupValidated]);
    return groupNormalized;
  } catch (err) {
    console.log('Error:setGroupGeotabCompanyIdWithService');
    console.log(err);
    throw err;
  }
}
export async function removeGroupCamerasWithService(groupId: string) {
  try {
    const response = await postRequestToService<'owlPartnerPoolGroup'>(GatewayPathsPCP.GROUP_REMOVE_CAMERAS, JSON.stringify({ groupId }));
    console.log("removeGroupCamerasWithService");
    console.log(response);
    return response;
  } catch (err) {
    console.log('Error:removeGroupCamerasWithService');
    console.log(err);
    throw err;
  }
}

export async function deleteGroupWithService(groupId: string): Promise<string> {
  let groupResponse: unknown;
  try {
    groupResponse = await postRequestToService<'owlPartnerPoolGroup'>(GatewayPathsPCP.GROUP_DELETE, JSON.stringify({ groupId }));
    console.log("groupResponse");
    console.log(groupResponse);
    return groupId;
  } catch (err) {
    console.log('Error:deleteGroupWithService');
    console.log(err);
    throw err;
  }
}