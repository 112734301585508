import { AsyncItemsStateI, AsyncRequestStateI } from "state/modules/module.reducers";

export enum GroupsActionType {
  GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE',
  CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST',
  CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE',
  REMOVE_ALL_CAMERAS_REQUEST = 'REMOVE_ALL_CAMERAS_REQUEST',
  REMOVE_ALL_CAMERAS_SUCCESS = 'REMOVE_ALL_CAMERAS_SUCCESS',
  REMOVE_ALL_CAMERAS_FAILURE = 'REMOVE_ALL_CAMERAS_FAILURE',
  DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE',
  SET_GROUP_GEOTAB_COMPANY_ID_REQUEST = 'SET_GROUP_GEOTAB_COMPANY_ID_REQUEST',
  SET_GROUP_GEOTAB_COMPANY_ID_SUCCESS = 'SET_GROUP_GEOTAB_COMPANY_ID_SUCCESS',
  SET_GROUP_GEOTAB_COMPANY_ID_FAILURE = 'SET_GROUP_GEOTAB_COMPANY_ID_FAILURE',
}

interface GroupRequestStateI {
  group: AsyncRequestStateI;
}

export type GroupsReducerStateI = AsyncItemsStateI<string> & GroupRequestStateI;