// RESELECT
import { createSelector } from "reselect";

// APP STATE
import { AppStateI } from "state/modules/root.reducer";

// SHARED SELECTORS 
import { getUsersArray } from "../modules/users.selectors";

// TYPES
import { UserTableItem } from "./selector.tables.types";
import { getUserEntities } from "../modules/entities.selectors";
import { UserI } from "models/User/User.model";

export const getUserTablePage = (state: AppStateI) => state.tables.user.page;
export const getUserTableRowsPerPage = (state: AppStateI) => state.tables.user.rowsPerPage;
export const getSelectedUserId = (state: AppStateI) => state.tables.user.selectedItemId;
export const getUserTableSelectedItemId = (state: AppStateI) => state.tables.user.selectedItemId;


export function selectedUserIdTypeGuard(selectedUserId: string | undefined): selectedUserId is string {
  if (selectedUserId && typeof selectedUserId === 'string') {
    return true;
  }
  return false;
}

export const getIsUserSelected = createSelector(
  [getUserTableSelectedItemId],
  (selectedItemId) => {
    if (selectedUserIdTypeGuard(selectedItemId)) {
      return true;
    }
    return false;
  }
)

export const getSelectedUser = createSelector(
  [getUserTableSelectedItemId, getUserEntities],
  (selectedItemId, userEntities) => {
    let user: UserI | undefined;
    if (selectedUserIdTypeGuard(selectedItemId)) {
      user = userEntities[selectedItemId];
    }
    return user;
  }
)

export const getUserTableCount = createSelector(
  [getUsersArray], 
  (items) => {
    return items.length;
  }
)

export const getUserTablePaginationIndexes = createSelector(
  [getUserTablePage, getUserTableRowsPerPage],
  (page, rowsPerPage) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    return [ startIndex, endIndex ];
  }
)

export const getUsersByPagination = createSelector(
  [getUserTablePaginationIndexes, getUsersArray],
  ([startIndex, endIndex], users) => {
    return users.slice(startIndex, endIndex);
  }
)

export const getPaginatedUserTableItems = createSelector(
  [getUsersByPagination, getUserTableSelectedItemId], 
  (users, selectedItemId) => {
    let userTableItems: UserTableItem[];
    userTableItems = users.map((user) => {
      return {
        ...user,
        itemId: user.userId,
        isSelected: user.userId === selectedItemId,
      }
    }); 
    return userTableItems;
  }
)
