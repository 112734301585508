// REACT
import React from 'react';

// PROPS
import { CameraTableRowProps } from './CameraTable';

// MATERIAL COMPONENTS
import { TableRow, TableCell, Checkbox, IconButton } from '@material-ui/core';

// MATERIAL ICONS
import CreateIcon from '@material-ui/icons/Create';

// APP COMPONENTS
import { CameraGroupButtonSwitch } from 'app/components/Buttons/CameraGroupButtonSwitch';
// import { CreditChips } from 'app/components/Credits/CreditChips';
// import { NetworkQualityIcon } from 'app/components/Icons/NetworkQualityIcon';

const CameraTableRow: React.FC<CameraTableRowProps> = ({ item, toggleCameraDialog, toggleAssetDialog,handleSelect }) => {
  const handleSelectWithItemId = () => {
    console.log('handleSelectWithItemId');
    console.log('item.itemId', item.itemId);
    if (typeof handleSelect === 'function') {
      handleSelect(item.itemId);
    }
  }

  const handleClickGroup = () => {
    handleSelectWithItemId();
    toggleCameraDialog();
  }

  const handleClickAsset = () => {
    handleSelectWithItemId();
    toggleAssetDialog();
  }

  return (
    <TableRow
      hover
      onClick={handleSelectWithItemId}
      role="checkbox"
      aria-checked={item.isSelected}
      tabIndex={-1}
      selected={item.isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={item.isSelected}
          inputProps={{ 'aria-labelledby': item.itemId }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {item.cameraSerialNumber}
      </TableCell>
      <TableCell>{item.cameraState}</TableCell>
      <TableCell>{item.groupName}</TableCell>
      <TableCell>
        <CameraGroupButtonSwitch
          groupId={item.groupId}
          handleClick={handleClickGroup}
        />
      </TableCell>
      <TableCell>
        {item.externalMetadata || '-'}
      </TableCell>
      <TableCell>
        <IconButton onClick={handleClickAsset}>
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default CameraTableRow;
