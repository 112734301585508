// react
import React from 'react';

// MODELS
import { CAMERA_DEFAULT_GROUP } from 'models/Camera/Camera.model';

// MATERIAL COMPONENT
import Button from '@material-ui/core/Button';

// STYLES 
import 'app/styles/components/buttons.styles.scss';

interface PropsI {
  groupId: string;
  handleClick: () => void;
}

export const CameraGroupButtonSwitch: React.FC<PropsI> = ({ groupId, handleClick }) => {
  if (groupId === CAMERA_DEFAULT_GROUP) {
    return (
      <Button onClick={handleClick} color="secondary">
        Assign
      </Button> 
    )
  }
  return (
    <Button onClick={handleClick} className="button-delete">
      Unassign
    </Button>
  )
}