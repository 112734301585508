// react
import React from 'react';

// MATERIAL COMPONENT
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


interface PropsI {
  title: string;
  text: string;
  isOpen: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export const ConfirmationDialog: React.FC<PropsI> = ({ title, text, isOpen, handleConfirm, handleCancel }) => {
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}