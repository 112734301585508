// AMPLIFY
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

export async function getAuthorizationFromAmplify(): Promise<string | undefined> {
  let identityToken: string | undefined;
  try {
    const session = await Auth.currentSession();
    console.log("session");
    console.log(session);
    identityToken = session.getIdToken().getJwtToken();
    return identityToken;
  } catch (err) {
    console.log("ERROR:getAuthorizationFromAmplify");
    console.log(err);
    throw err;
  }
}


function cognitoUserTypeGuard(user: CognitoUser | undefined): user is CognitoUser {
  if (user) {
    return true;
  }
  return false;
}

export async function getAuthorizedUser(): Promise<CognitoUser> {
  const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
  if (cognitoUserTypeGuard(user)) {
    return user;
  } else {
    throw new Error("UnauthorizedUser");
  }
}

export async function getAuthorizedUserAttributes(): Promise<CognitoUserAttribute[]> {
  const user = await getAuthorizedUser();
  return new Promise((resolve, reject) => {
    user.getUserAttributes((err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export async function getAuthorizedUserId(): Promise<string> {
  const user = await getAuthorizedUser();
  return user.getUsername();
}

export async function getAuthorizedUserPartnerPoolId(): Promise<string | undefined> {
  const userAttributes = await getAuthorizedUserAttributes();
  let partnerPoolAttribute = userAttributes.filter((attribute) => attribute.getName() === 'custom:partnerPoolId')[0];
  if (!partnerPoolAttribute) {
    return;
  } else {
    return partnerPoolAttribute.getValue();
  }
}

export async function signOutWithAmplify() {
  return await Auth.signOut({ global: true })
    .then(data => console.log(data))
    .catch(err => console.log(err));
}