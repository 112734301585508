// MODULE TYPES
import { TablesActionsUnion } from './tables.actions';
import { TablesActionType, TableId, TablesReducerStateI, TableStateI } from './tables.types';

// UTILS
import { mergeState } from '../module.reducers';

const initialState: TablesReducerStateI = {
  camera: {
    tableId: TableId.CAMERA,
    page: 0,
    rowsPerPage: 25,
    selectedItemId: undefined,
  },
  group: {
    tableId: TableId.GROUP,
    page: 0,
    rowsPerPage: 10,
    selectedItemId: undefined,
  },
  user: {
    tableId: TableId.USER,
    page: 0,
    rowsPerPage: 10,
    selectedItemId: undefined,
  }
};

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_MODE === 'camera_selected') {
  initialState.camera.selectedItemId = 'P917-DFA-CDMM';
}

function tableReducer(state: TableStateI, action: TablesActionsUnion) {
  if (state.tableId !== action.tableId) {
    return state;
  }

  switch (action.type) {
    case TablesActionType.SET_PAGE: {
      return mergeState(state, {
        page: action.page,
        selectedItemId: undefined,
      });
    }
    case TablesActionType.SET_ROWS_PER_PAGE: {
      return mergeState(state, {
        rowsPerPage: action.rowsPerPage,
      });
    }
    case TablesActionType.SELECT_TABLE_ITEM: {
      return mergeState(state, {
        selectedItemId: action.tableItemId
      })
    }
    case TablesActionType.UNSELECT_TABLE_ITEM: {
      return mergeState(state, {
        selectedItemId: undefined
      });
    }
    default: 
      return state;
  }
}


export function tablesReducer(state: TablesReducerStateI = initialState, action: TablesActionsUnion) {
  switch (action.type) {
    case TablesActionType.SET_PAGE: 
    case TablesActionType.SET_ROWS_PER_PAGE: 
    case TablesActionType.SELECT_TABLE_ITEM:
    case TablesActionType.UNSELECT_TABLE_ITEM:
      return {
        ...state,
        camera: tableReducer(state.camera, action),
        group: tableReducer(state.group, action),
        user: tableReducer(state.user, action),
      }
    default:
      return state;
  }
}