// react
import React from 'react';

// PROPS
import { PaginatedTableProps, TableItemWithId } from './PaginatedTable.types';

// MATERIAL COMPONENTS
import { Table, TableBody, } from '@material-ui/core';

// PAGINATED TABLE COMPONENTS
import { PaginatedTableFooter } from './PaginatedTableFooter';

export class PaginatedTable<T extends TableItemWithId> extends React.Component<PaginatedTableProps<T>> {

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const { count, rowsPerPage, setPage } = this.props;
    const pageMax = Math.floor(count / rowsPerPage);
    if (page < 0) {
      page = 0;
    } else if (page >= pageMax) {
      page = pageMax;
    }

    setPage(page);
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { setRowsPerPage } = this.props;
    let rowsPerPage: number;
    try {
      rowsPerPage = parseInt(event.currentTarget.value);
      setRowsPerPage(rowsPerPage);
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { tableKey, headerComponent, items, page, rowsPerPage, count } = this.props;
    return (
    <React.Fragment>
        <Table stickyHeader size="small" style={{maxHeight: '95%', overflow: 'auto'}}>
          {headerComponent}
          <TableBody>
            {items.map((item, index) => {
              const key = `pt-${tableKey}-${index}-${item.itemId}`;
              return this.props.renderRow(key, item, index);
            })}
          </TableBody>
          <PaginatedTableFooter
            page={page}
            count={count}
            rowsPerPage={rowsPerPage}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Table>
      </React.Fragment>
    );
  }
}


  // handleIncrementPage = () => {
  //   const { items } = this.props;
  // const { page, rowsPerPage } = this.state;
  //   const pageMax = Math.floor(items.length / rowsPerPage);
  //   if (page === pageMax) {
  //     return;
  //   }
  //   this.setState({ page: page + 1 });
  // }

  // handleDecrementPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   const { page } = this.state;
  //   if (page === 0) {
  //     return;
  //   }
  //   this.setState({ page: page + 1 });
  // }
