// ACTION TYPES
import { createAction, ActionsUnionT } from 'state/modules/module.actions';

// MODULE TYPES
import { ThemeActionType } from './theme.types';
import { ThemeMode } from './theme.reducer';

// BASIC ACTION CREATORS
export const setThemeActionCreator = (theme: ThemeMode) => createAction(ThemeActionType.SET_THEME, { theme });
export type setThemeActionT = ReturnType<typeof setThemeActionCreator>;

export const ThemeActionCreators = {
  setTheme: setThemeActionCreator,
};

export type ThemeActionsUnion = ActionsUnionT<typeof ThemeActionCreators>;