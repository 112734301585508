export enum GatewayName {
  TEST = 'https://2ryhufbq9f.execute-api.us-west-2.amazonaws.com/v1',
  OEP = 'https://api.partner.owlcam.com/v1',
  OESSO = 'https://api.oesso.owlcam.com/v1',
  OEEP = 'https://api.oeep.owlcam.com/v1',
  PCP = 'https://4k47djpk58.execute-api.us-west-2.amazonaws.com/v1'
}

export enum GatewayPathsPCP {
  CAMERAS = '/camera',
  CAMERA_METADATA = '/camera/metadata',
  CAMERA_GROUP = '/camera/group',
  GROUPS = '/group',
  PARTNER = '/partner',
  USERS = '/user',
  GROUP_CREATE = '/group/create',
  GROUP_DELETE = '/group/delete',
  GROUP_REMOVE_CAMERAS = '/group/removeAllCameras',
  GROUP_SET_GEOTAB_COMPANY_ID = '/group/updateGeotabCompany',
  USER_PERMISSION = '/user/permission',
  USER_CREATE = '/user/create',
  USER_DELETE = '/user/delete',
}

export function getGatewayPCPInvokeUrl(path: GatewayPathsPCP) {
  return GatewayName.PCP + path;
}