// REDUCER TYPE AND UTIL
import { AsyncItemStateI, initialAsyncItemState, mergeState } from "../module.reducers";

// ACTIONS
import { PartnerActionsUnion } from "./partner.actions";

// ACTION TYPES
import { PartnerActionTypes } from "./partner.types";

// MODELS
import { PartnerI } from "models/Partner/Partner.model";

export type PartnerReducerStateI = AsyncItemStateI<PartnerI>;

const initialState: PartnerReducerStateI = initialAsyncItemState;

export function partnerReducer(state: PartnerReducerStateI = initialState, action: PartnerActionsUnion) {
  switch (action.type) {
    case PartnerActionTypes.GET_PARTNER_REQUEST: {
        return mergeState(state, {
          isFetching: true,
          isValid: false
        });
      }
    case PartnerActionTypes.GET_PARTNER_SUCCESS: {
        return mergeState(state, {
          item: action.partner,
          isFetching: false,
          isValid: true
        });
      }
    case PartnerActionTypes.GET_PARTNER_FAILURE: {
        return mergeState(state, {
          isFetching: false,
          errorMessage: action.errorMessage
        });
      }    
    default:
      return state;
    }
  }