// REACT
import React from 'react';

// PROPS
import { GroupTableRowProps } from './GroupTable';

// MATERIAL COMPONENTS
import { TableRow, TableCell, Button } from '@material-ui/core';

// STYLES 
import 'app/styles/components/buttons.styles.scss';
import GroupTableCellExternalProvider from './GroupTableCell.ExternalProvider';

const GroupTableRow: React.FC<GroupTableRowProps> = ({ item, handleSelect, isAuthUserAdmin, externalProvider, handleDelete, toggleExternalProviderDialog }) => {
  const handleSelectWithItemId = () => {
    console.log('handleSelectWithItemId');
    console.log('item.itemId', item.itemId);
    if (typeof handleSelect === 'function') {
      handleSelect(item.itemId);
    }
  }

  const handleClickDelete = () => {
    console.log("handleClickDelete");
    handleDelete(item.itemId);
  }

  const handleClickExternalProvider = () => {
    handleSelectWithItemId();
    toggleExternalProviderDialog();
  }

  return (
    <TableRow
      hover
      onClick={handleSelectWithItemId}
      tabIndex={-1}
      selected={item.isSelected}
    >
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.contactEmail}</TableCell>
      <GroupTableCellExternalProvider 
        item={item}
        externalProvider={externalProvider} 
        handleClick={handleClickExternalProvider}
      />
      <TableCell>
        <Button onClick={handleClickDelete} disabled={!isAuthUserAdmin} className="button-delete">
          Delete
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default GroupTableRow;
