// REDUX
import { Action, ActionCreatorsMapObject } from 'redux';

// DEFINE TYPES FOR ACTION WITH PAYLOAD
interface PayloadI {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [attribute: string]: any;
}

export type ActionWithPayload<T extends string, P extends PayloadI> = Action<T> & P;

// OVERLOADED CREATE ACTION FN
export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P extends PayloadI>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P extends PayloadI>(type: T, payload?: P) {
  if (payload) {
    let actionWithPayload: Action<T> & P;
    actionWithPayload = {
      type,
      ...payload,
    };
    return actionWithPayload;
  } else {
    return {
      type,
    };
  }
}

export type ActionsUnionT<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;
