import React from 'react';

interface PropsI {
  height?: number;
  fill?: string;
}

export const OwlcamLogo: React.FC<PropsI> = ({ height = 140, fill = '#FFF' }) => {
  // export default function OwlcamLogo(props) {
  return (
		<div>

    <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531 136" style={{ marginRight: 12 }}>
      <g fill={fill} fillRule="nonzero">
        <g>
          <path
            d="M69.15,117.28h-2.22c-4.77,0-9.21-0.9-13.32-2.71c-4.11-1.81-7.69-4.23-10.73-7.27c-3.04-3.04-5.47-6.62-7.27-10.73
					c-1.81-4.11-2.71-8.55-2.71-13.32c0-4.6,0.9-9,2.71-13.19c1.81-4.19,4.23-7.81,7.27-10.85c3.04-3.04,6.62-5.46,10.73-7.27
					c4.11-1.81,8.55-2.71,13.32-2.71h2.22c4.6,0,9,0.91,13.19,2.71c4.19,1.81,7.81,4.23,10.85,7.27c3.04,3.04,5.46,6.66,7.27,10.85
					c1.81,4.19,2.71,8.59,2.71,13.19c0,4.77-0.91,9.21-2.71,13.32c-1.81,4.11-4.23,7.69-7.27,10.73c-3.04,3.04-6.66,5.47-10.85,7.27
					C78.15,116.37,73.75,117.28,69.15,117.28z M66.93,64.26c-5.26,0-9.78,1.85-13.56,5.55c-3.78,3.7-5.67,8.18-5.67,13.44
					c0,5.26,1.89,9.78,5.67,13.56c3.78,3.78,8.3,5.67,13.56,5.67h2.22c2.63,0,5.09-0.49,7.4-1.48c2.3-0.99,4.32-2.34,6.04-4.07
					c1.73-1.73,3.08-3.78,4.07-6.16c0.99-2.38,1.48-4.89,1.48-7.52c0-5.26-1.85-9.74-5.55-13.44s-8.18-5.55-13.44-5.55H66.93z"
          />
          <path
            d="M175.93,117.28c-1.48,0-2.88-0.41-4.19-1.23c-1.32-0.82-2.22-1.97-2.71-3.45l-14.06-35.51l-13.81,35.51
					c-0.66,1.32-1.6,2.43-2.84,3.33c-1.23,0.91-2.59,1.36-4.07,1.36c-3.45,0-5.84-1.56-7.15-4.69l-20.71-53.26
					c-0.82-1.81-0.82-3.66,0-5.55c0.82-1.89,2.22-3.24,4.19-4.07c1.81-0.82,3.66-0.78,5.55,0.12c1.89,0.91,3.24,2.26,4.07,4.07
					l14.06,35.51l13.81-35.51c0.66-1.31,1.6-2.42,2.84-3.33c1.23-0.9,2.59-1.36,4.07-1.36c3.45,0,5.75,1.56,6.9,4.69l14.06,35.51
					l14.06-35.51c0.82-1.81,2.13-3.16,3.95-4.07c1.81-0.9,3.7-0.94,5.67-0.12c1.81,0.82,3.16,2.18,4.07,4.07
					c0.9,1.89,0.94,3.74,0.12,5.55l-20.96,53.26C181.68,115.72,179.38,117.28,175.93,117.28z"
          />
          <path
            d="M221.79,117.28c-2.14,0-3.91-0.7-5.3-2.1c-1.4-1.39-2.1-3.16-2.1-5.3V30.72c0-2.13,0.7-3.95,2.1-5.42
					c1.4-1.48,3.16-2.22,5.3-2.22c2.13,0,3.9,0.74,5.3,2.22c1.39,1.48,2.1,3.29,2.1,5.42v79.16c0,2.14-0.7,3.91-2.1,5.3
					C225.69,116.58,223.92,117.28,221.79,117.28z"
          />
          <path
            d="M275.3,117.28h-2.22c-4.77,0-9.21-0.9-13.32-2.71c-4.11-1.81-7.69-4.23-10.73-7.27c-3.04-3.04-5.47-6.62-7.27-10.73
					c-1.81-4.11-2.71-8.55-2.71-13.32c0-4.6,0.9-9,2.71-13.19c1.81-4.19,4.23-7.81,7.27-10.85c3.04-3.04,6.62-5.46,10.73-7.27
					c4.11-1.81,8.55-2.71,13.32-2.71h2.22c4.6,0,9,0.91,13.19,2.71c4.19,1.81,7.93,4.36,11.22,7.64c1.48,1.48,2.18,3.29,2.1,5.42
					c-0.09,2.14-0.86,3.87-2.34,5.18c-1.48,1.48-3.25,2.22-5.3,2.22c-2.06,0-3.74-0.74-5.05-2.22c-3.78-3.95-8.39-5.92-13.81-5.92
					h-2.22c-5.26,0-9.78,1.85-13.56,5.55c-3.78,3.7-5.67,8.18-5.67,13.44c0,5.26,1.89,9.78,5.67,13.56
					c3.78,3.78,8.3,5.67,13.56,5.67h2.22c5.26,0,9.86-1.97,13.81-5.92c1.31-1.48,3-2.22,5.05-2.22c2.05,0,3.82,0.66,5.3,1.97
					c1.48,1.48,2.26,3.25,2.34,5.3c0.08,2.06-0.62,3.82-2.1,5.3c-3.29,3.29-7.03,5.84-11.22,7.64
					C284.3,116.37,279.9,117.28,275.3,117.28z"
          />
          <path
            d="M371.39,117.28c-1.81,0-3.41-0.53-4.81-1.6c-1.4-1.07-2.26-2.5-2.59-4.32c-5.76,3.95-12.17,5.92-19.23,5.92h-2.22
					c-4.77,0-9.21-0.9-13.32-2.71c-4.11-1.81-7.69-4.23-10.73-7.27c-3.04-3.04-5.47-6.62-7.28-10.73
					c-1.81-4.11-2.71-8.55-2.71-13.32c0-4.6,0.9-9,2.71-13.19c1.81-4.19,4.23-7.81,7.28-10.85c3.04-3.04,6.62-5.46,10.73-7.27
					c4.11-1.81,8.55-2.71,13.32-2.71h2.22c7.07,0,13.48,1.97,19.23,5.92c0.33-1.64,1.19-3.04,2.59-4.19c1.39-1.15,3-1.73,4.81-1.73
					c1.97,0,3.7,0.74,5.18,2.22c1.48,1.48,2.22,3.21,2.22,5.18v53.27c0,2.14-0.74,3.91-2.22,5.3
					C375.09,116.58,373.36,117.28,371.39,117.28z M342.54,64.26c-5.26,0-9.78,1.85-13.56,5.55c-3.78,3.7-5.67,8.18-5.67,13.44
					c0,5.26,1.89,9.78,5.67,13.56c3.78,3.78,8.3,5.67,13.56,5.67h2.22c2.63,0,5.09-0.49,7.4-1.48c2.3-0.99,4.32-2.34,6.04-4.07
					c1.73-1.73,3.08-3.78,4.07-6.16c0.99-2.38,1.48-4.89,1.48-7.52c0-5.26-1.85-9.74-5.55-13.44c-3.7-3.7-8.18-5.55-13.44-5.55
					H342.54z"
          />
          <path
            d="M458.93,49.22c3.95,0,7.6,0.74,10.97,2.22c3.37,1.48,6.37,3.54,9,6.16c2.63,2.63,4.69,5.63,6.16,9
					c1.48,3.37,2.22,7.03,2.22,10.97v32.3c0,2.14-0.74,3.91-2.22,5.3c-1.48,1.4-3.21,2.1-5.18,2.1c-2.14,0-3.95-0.7-5.42-2.1
					c-1.48-1.39-2.22-3.16-2.22-5.3v-32.3c0-3.78-1.28-6.94-3.82-9.49c-2.55-2.55-5.71-3.82-9.49-3.82c-3.78,0-6.99,1.27-9.62,3.82
					c-2.63,2.55-3.95,5.71-3.95,9.49v32.3c0,2.14-0.7,3.91-2.1,5.3c-1.4,1.4-3.17,2.1-5.3,2.1c-2.14,0-3.91-0.7-5.3-2.1
					c-1.4-1.39-2.1-3.16-2.1-5.3v-32.3c0-3.78-1.28-6.94-3.82-9.49c-2.55-2.55-5.71-3.82-9.49-3.82c-3.78,0-6.99,1.27-9.62,3.82
					c-2.63,2.55-3.95,5.71-3.95,9.49v32.3c0,2.14-0.74,3.91-2.22,5.3c-1.48,1.4-3.21,2.1-5.18,2.1c-2.14,0-3.95-0.7-5.42-2.1
					c-1.48-1.39-2.22-3.16-2.22-5.3v-32.3V56.61c0-1.97,0.74-3.7,2.22-5.18c1.48-1.48,3.29-2.22,5.42-2.22
					c1.31,0,2.55,0.37,3.7,1.11c1.15,0.74,2.05,1.69,2.71,2.84c4.44-2.63,9.29-3.95,14.55-3.95c4.11,0,7.93,0.86,11.47,2.59
					c3.53,1.73,6.62,3.99,9.25,6.78c2.63-2.79,5.71-5.06,9.25-6.78C450.75,50.08,454.65,49.22,458.93,49.22z"
          />
        </g>
        <g>
          <path
            d="M490.45,59.2v-7.53h-2.19c-0.54,0-0.99-0.44-0.99-0.98c0-0.55,0.46-0.99,0.99-0.99h6.63c0.54,0,0.99,0.44,0.99,0.99
					c0,0.54-0.46,0.98-0.99,0.98h-2.19v7.53c0,0.6-0.5,1.12-1.12,1.12C490.95,60.32,490.45,59.8,490.45,59.2z"
          />
          <path
            d="M506.32,59.2v-6.55l-2.85,7.26c-0.09,0.24-0.33,0.41-0.6,0.41c-0.27,0-0.5-0.17-0.6-0.41l-2.85-7.26v6.55
					c0,0.6-0.49,1.12-1.1,1.12c-0.63,0-1.13-0.52-1.13-1.12v-7.78c0-1.01,0.82-1.83,1.83-1.83c0.79,0,1.45,0.49,1.72,1.18l2.14,5.48
					l2.16-5.48c0.25-0.69,0.93-1.18,1.7-1.18c1.02,0,1.84,0.82,1.84,1.83v7.78c0,0.6-0.5,1.12-1.13,1.12
					C506.83,60.32,506.32,59.8,506.32,59.2z"
          />
        </g>
      </g>
    </svg>
		</div>
  );
};
