import { createSelector } from 'reselect';
import { AppStateI } from 'state/modules/root.reducer';
import { getUserEntities } from './entities.selectors';
import { UserI } from 'models/User/User.model';

export const getUsersFetchingState = (state: AppStateI) => state.users.isFetching;
export const getUsersValidState = (state: AppStateI) => state.users.isValid;
export const getUserItems = (state: AppStateI) => state.users.items;

export const shouldFetchUsersSelector = createSelector(
  [getUsersFetchingState, getUsersValidState],
  (isFetching, isValid) => {
    let shouldFetch = false;
    if (!isFetching && !isValid) {
      shouldFetch = true;
    }
    return shouldFetch;
  },
);


export const getUsersArray = createSelector(
  [getUsersValidState, getUserItems, getUserEntities],
  (isValid, items, entities) => {
    let users: UserI[] = [];
    if (!isValid) {
      return users;
    }
    users = items.map((itemId) => entities[itemId]);
    return users;
  }
)