// ACTIONS
import { CamerasActionsUnion } from './cameras.actions';
import { CamerasActionType, CamerasReducerStateI } from './cameras.types';

// REDUCER UTILS
import { mergeState } from 'state/modules/module.reducers';

// SUB REDUCER
import { cameraReducer } from './reducers/camera.reducer';

// LIB
import _ from 'lodash';

const initialState: CamerasReducerStateI = {
  items: [],
  isValid: false,
  isFetching: false,
  errorMessage: undefined,
  camera: {
    request: undefined,
    isRequesting: false,
    isSuccess: false,
    errorMessage: undefined,
  }
};

export function camerasReducer(state: CamerasReducerStateI = initialState, action: CamerasActionsUnion) {
  switch (action.type) {
    case CamerasActionType.GET_CAMERAS_REQUEST: {
      return mergeState(state, {
        isValid: false,
        isFetching: true,
        errorMessage: undefined,
      });
    }
    case CamerasActionType.GET_CAMERAS_SUCCESS: {
      const concat = [...state.items, ...action.result];
      const items = _.uniq(concat);
      return mergeState(state, {
        items,
        isValid: true,
        isFetching: false,
      });
    }
    case CamerasActionType.GET_CAMERAS_FAILURE: {
      return mergeState(state, {
        isValid: true,
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    }
    case CamerasActionType.ADD_CAMERA_REQUEST:
    case CamerasActionType.ADD_CAMERA_SUCCESS:
    case CamerasActionType.ADD_CAMERA_FAILURE:
    case CamerasActionType.REMOVE_CAMERA_REQUEST:
    case CamerasActionType.REMOVE_CAMERA_SUCCESS:
    case CamerasActionType.REMOVE_CAMERA_FAILURE:
    case CamerasActionType.UPDATE_CAMERA_METADATA_REQUEST:
    case CamerasActionType.UPDATE_CAMERA_METADATA_SUCCESS:
    case CamerasActionType.UPDATE_CAMERA_METADATA_FAILURE:
      return {
        ...state,
        camera: cameraReducer(state.camera, action)
      }
    default:
      return state;
  }
}
