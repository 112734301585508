// REACT
import React from 'react';

// PROPS
import { PaginatedTableRowPropsI, PaginatedTableReduxI } from 'app/components/PaginatedTableHOC/PaginatedTable.types';
import { PaginatedTable } from 'app/components/PaginatedTableHOC/PaginatedTableHOC';

// APP COMPONENTS
import { GroupTableHeader } from './GroupTable.header';
import GroupTableRow from './GroupTable.row';

// MODELS
import { GroupTableItem } from 'state/selectors/tables/selector.tables.types';

// PROPS 
import { GroupTableOwnProps } from 'app/pages/Groups.page';

export type GroupTableRowProps = PaginatedTableRowPropsI<GroupTableItem> & GroupTableOwnProps;

type Props = PaginatedTableReduxI<GroupTableItem> & GroupTableOwnProps;

// EXTENDED CLASS
class GroupTableWrapper extends PaginatedTable<GroupTableItem> {}

const GroupTable: React.FC<Props> = (props) => {
  return (
      <GroupTableWrapper
        tableKey='cameras'
        title="Groups"
        headerComponent={<GroupTableHeader externalProvider={props.externalProvider}/>}
        {...props}
        renderRow={(key: string, item: GroupTableItem, index: number) => (
          <GroupTableRow
            key={key}
            item={item}
            index={index}
            isAuthUserAdmin={props.isAuthUserAdmin}
            externalProvider={props.externalProvider}
            handleSelect={props.handleSelect}
            handleDelete={props.handleDelete}
            toggleExternalProviderDialog={props.toggleExternalProviderDialog}
          />
        )}
      />
  );
}

export default GroupTable;
