export const GroupStateEnumForSchema = ['active', 'inactive', 'allocated'];

export interface GroupI {
  partnerPoolId: string;
  groupId: string;
  dateCreated: number;
  dateModified: number;
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  contactPrimaryFirstname: string;
  contactPrimaryLastname: string;
  contactEmail: string;
  contactPhoneNumber: string;
  geotabCompanyId?: string;
  geotabServiceAccountUserName?: string;
  geotabServiceAccountPassword?: string;
  geotabServiceAccountDatabase?: string;
  geotabServiceAccountServer?: string;
  geotabServiceAccountId?: string;
  geotabServiceAccountError?: boolean;
  dateGeotabServiceAccountUpdated?: number;
  dateGeotabServiceDataPolled?: number;
}

// ------------------------------------------------------------
// GROUP GUARD TYPES
// ------------------------------------------------------------
export type GroupWithId = GroupI & { groupId: string };

export type GroupWithGroupId = GroupI & { groupId: string };

// ------------------------------------------------------------
// GROUP CREATE
// ------------------------------------------------------------
export type GroupCreateParams = Pick<GroupI, 'name' | 'address' | 'city' | 'state' | 'country' | 'contactPrimaryFirstname' | 'contactPrimaryLastname' | 'contactEmail' | 'contactPhoneNumber'>

export const GroupCreateDefault: GroupCreateParams = {
  name: "",
  address: "",
  city: "",
  state: "",
  country: "",
  contactPrimaryFirstname: "",
  contactPrimaryLastname: "",
  contactEmail: "",
  contactPhoneNumber: "",
}



























