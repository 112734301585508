import React, { Component } from 'react'

// DRAWER
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// ICONS
import GroupIcon from '@material-ui/icons/Group';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

// REACT ROUTER
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';


interface StateI {
  redirect: string | undefined;
}

class NavDrawer extends Component<RouteComponentProps, StateI> {

  state: StateI = {
    redirect: undefined,
  }

  componentDidMount() {
    // console.log(this.props);
  }

  handleClick = (redirect: string) => {
    const { history } = this.props;
    history.push(redirect);
    // this.setState({ redirect });
  }

  render() {
    const { location } = this.props;
    return (
      <Drawer 
        variant="permanent" 
        anchor="left" 
        className="nav-drawer" 
        classes={{
          paper: 'nav-drawer-paper',
        }}
      >
        <List>
          <ListItem button onClick={() => this.handleClick('/cameras')} selected={location.pathname === '/cameras'}>
            <ListItemIcon><CameraAltIcon /></ListItemIcon>
            <ListItemText primary={'Cameras'} />
          </ListItem>
          <ListItem button onClick={() => this.handleClick('/groups')} selected={location.pathname === '/groups'}>
            <ListItemIcon><LocalShippingIcon /></ListItemIcon>
            <ListItemText primary={'Groups'} />
          </ListItem>
          <ListItem button onClick={() => this.handleClick('/users')} selected={location.pathname === '/users'}>
            <ListItemIcon><GroupIcon /></ListItemIcon>
            <ListItemText primary={'Users'} />
          </ListItem>
        </List>
      </Drawer>
    )
  }
}

export default withRouter(NavDrawer);