// REACT
import React from 'react';

// MATERIAL COMPONENTS
import { TableRow, TableFooter, TablePagination } from '@material-ui/core';

// PROPS
import { PaginationFooterPropsI } from './PaginatedTable.types';
// import { PaginationFooterPropsI } from './PaginatedTableHOC';

const PaginationSelectProps = {
  inputProps: { 'aria-label': 'rows per page' },
  native: true,
}
export const PaginatedTableFooter: React.FC<PaginationFooterPropsI> = ({ count, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          onChangePage={handleChangePage}
          SelectProps={PaginationSelectProps}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        // ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  )
}

