// react
import React from 'react';

// MATERIAL COMPONENT
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// MODEL
import { OwlPartnerPoolUserPermission } from 'models/User/User.model';

// STYLES 
import 'app/styles/components/forms.styles.scss'

interface PropsI {
  value: OwlPartnerPoolUserPermission;
  isDisabled?: boolean;
  handleChange: (value: OwlPartnerPoolUserPermission) => void;
}

function userPermissionTypeGuard (str: string): str is OwlPartnerPoolUserPermission {
  if (str === OwlPartnerPoolUserPermission.ADMIN || str === OwlPartnerPoolUserPermission.MANAGER) {
    return true;
  }
  return false;
}


const useStyles = makeStyles({
  label: {
    textTransform: 'capitalize',
    fontSize: '0.85rem'
  },
});

export const UserPermissionRadio: React.FC<PropsI> = ({ value, isDisabled = false, handleChange }) => {
  const classes = useStyles();

  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permission = (event.target as HTMLInputElement).value;
    if (userPermissionTypeGuard(permission)) {
      handleChange(permission);
    }
  };
  
  return (
    <FormControl component="fieldset" id="user-permission-fc">
      <RadioGroup aria-label="permissions" name="permission" value={value} onChange={_handleChange} className='user-permission-radio-grid'>
        <FormControlLabel value={OwlPartnerPoolUserPermission.ADMIN} control={<Radio />} label="Admin" classes={{ label: classes.label }} disabled={isDisabled} />
        <FormControlLabel value={OwlPartnerPoolUserPermission.MANAGER} control={<Radio />} label="Manager" classes={{ label: classes.label }} disabled={isDisabled}/>
      </RadioGroup>
    </FormControl>
  )
}