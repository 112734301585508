// NORMALIZR
import { schema, normalize } from 'normalizr';
import { NormalizedResponseFromArray, NormalizedEntities } from 'models/models.normalize';

// MODEL
import { UserI } from './User.model';

const userEntity = new schema.Entity('users', {}, { idAttribute: (user: UserI) => user.userId });

const userEntityArray = new schema.Array(userEntity);

export type NormalizedUsers = NormalizedResponseFromArray<'users', UserI>;

export function getNormalizedUsersFromArray(users: UserI[]): NormalizedUsers {
  let normalizedUsers: NormalizedResponseFromArray<'users', UserI>;
  normalizedUsers = normalize<UserI, NormalizedEntities<'users', UserI>, string[]>(users, userEntityArray);
  return normalizedUsers;
}