import { AsyncItemsStateI, AsyncRequestStateI } from "state/modules/module.reducers";

export enum CamerasActionType {
  GET_CAMERAS_REQUEST = 'GET_CAMERAS_REQUEST',
  GET_CAMERAS_SUCCESS = 'GET_CAMERAS_SUCCESS',
  GET_CAMERAS_FAILURE = 'GET_CAMERAS_FAILURE',
  ADD_CAMERA_REQUEST = 'ADD_CAMERA_REQUEST',
  ADD_CAMERA_SUCCESS = 'ADD_CAMERA_SUCCESS',
  ADD_CAMERA_FAILURE = 'ADD_CAMERA_FAILURE',
  REMOVE_CAMERA_REQUEST = 'REMOVE_CAMERA_REQUEST',
  REMOVE_CAMERA_SUCCESS = 'REMOVE_CAMERA_SUCCESS',
  REMOVE_CAMERA_FAILURE = 'REMOVE_CAMERA_FAILURE',
  UPDATE_CAMERA_METADATA_REQUEST = 'UPDATE_CAMERA_METADATA_REQUEST',
  UPDATE_CAMERA_METADATA_SUCCESS = 'UPDATE_CAMERA_METADATA_SUCCESS',
  UPDATE_CAMERA_METADATA_FAILURE = 'UPDATE_CAMERA_METADATA_FAILURE',
}

interface CameraRequestStateI {
  camera: AsyncRequestStateI;
}

export type CamerasReducerStateI = AsyncItemsStateI<string> & CameraRequestStateI;