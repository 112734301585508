// MODEL
import { PartnerI } from './Partner.model';

// SCHEMA
import { PartnerSchema } from './Partner.schema';

// VALIDATION UTILS
import { getValidatedTypeFromJson } from 'models/models.validation';

export function getValidatedPartner(json: unknown): PartnerI {
  return getValidatedTypeFromJson(json, PartnerSchema);
}
