import React, { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// SELECTORS
import { AppStateI } from 'state/modules/root.reducer';
import { isThemeDarkSelector } from 'state/selectors/modules/theme.selectors';

// ACTIONS
import { ThemeActionCreators } from 'state/modules/theme/theme.actions';

// MATERIAL
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Tooltip, IconButton, Button } from '@material-ui/core';

// MATERIAL ICONS
import InvertColorsIcon from '@material-ui/icons/InvertColors';

// APP COMPONENTS
import { OwlcamLogo } from '../Logos/OwlcamLogo';

// STYLES 
import 'app/styles/components/nav.styles.scss'
import { ThemeMode } from 'state/modules/theme/theme.reducer';
import { signOutThunk } from 'state/modules/auth/auth.thunks';

// MATERIAL
class NavBar extends Component<PropsI> {

  toggleTheme = () => {
    const { setTheme, isThemeDark } = this.props;
    if (isThemeDark) {
      setTheme(ThemeMode.LIGHT);
    } else {
      setTheme(ThemeMode.DARK);
    }
  }

  render() {
    const { isThemeDark, signOutThunk } = this.props;
    const logoFill = isThemeDark ? '#eee' : "#111"; 
    return (
      <AppBar className='appbar' position={'fixed'} color={"primary"}>
        <Toolbar variant={'dense'} className="appbar__toolbar">
          <OwlcamLogo height={30} fill={logoFill} />
          <div style={{display: 'flex'}}>
            <Tooltip title="Toggle light/dark theme" enterDelay={300}>
              <IconButton onClick={this.toggleTheme}>
                <InvertColorsIcon />
              </IconButton>
            </Tooltip>
            <Button onClick={signOutThunk}>
              Sign Out
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

// REDUX MAPPING
const mapStateToProps = (state: AppStateI) => ({
  isThemeDark: isThemeDarkSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTheme: ThemeActionCreators.setTheme,
      signOutThunk: signOutThunk,
    },
    dispatch,
  );

type PropsI = Partial<ReturnType<typeof mapStateToProps>> & ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar);