// ACTIONS
import { GroupsActionsUnion } from './groups.actions';
import { GroupsActionType, GroupsReducerStateI } from './groups.types';

// REDUCER UTILS
import { mergeState } from 'state/modules/module.reducers';

// SUB REDUCER
import { groupReducer } from './reducers/group.reducer';

const initialState: GroupsReducerStateI = {
  items: [],
  isValid: false,
  isFetching: false,
  errorMessage: undefined,
  group: {
    request: undefined,
    isRequesting: false,
    isSuccess: false,
    errorMessage: undefined,
  }
};

export function groupsReducer(state: GroupsReducerStateI = initialState, action: GroupsActionsUnion) {
  switch (action.type) {
    case GroupsActionType.GET_GROUPS_REQUEST: {
      return mergeState(state, {
        isValid: false,
        isFetching: true,
        errorMessage: undefined,
      });
    }
    case GroupsActionType.GET_GROUPS_SUCCESS: {
      return mergeState(state, {
        items: action.result,
        isValid: true,
        isFetching: false,
      });
    }
    case GroupsActionType.GET_GROUPS_FAILURE: {
      return mergeState(state, {
        isValid: true,
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    }
    case GroupsActionType.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...action.result],
        group: groupReducer(state.group, action)
      }
    case GroupsActionType.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item !== action.groupId),
        group: groupReducer(state.group, action)
      }
    case GroupsActionType.CREATE_GROUP_REQUEST:
    case GroupsActionType.CREATE_GROUP_FAILURE:
    case GroupsActionType.REMOVE_ALL_CAMERAS_REQUEST:
    case GroupsActionType.REMOVE_ALL_CAMERAS_SUCCESS:
    case GroupsActionType.REMOVE_ALL_CAMERAS_FAILURE:
    case GroupsActionType.DELETE_GROUP_REQUEST:
    case GroupsActionType.DELETE_GROUP_FAILURE:
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_REQUEST:
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_SUCCESS:
    case GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_FAILURE:
      return {
        ...state,
        group: groupReducer(state.group, action)
      }
    default:
      return state;
  }
}
