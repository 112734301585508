// ENUM
import { CameraStateEnumForSchema } from './Camera.model';

// SCHEMA MODEL
import { SchemaArrayI } from 'models/models.validation';

export const CameraSchema = {
  type: 'object',
  properties: {
    partnerPoolId: { type: 'string' },
    cameraSerialNumber: { type: 'string' },
    cameraId: { type: 'string' },
    cameraState: { type: 'string', enum: CameraStateEnumForSchema },
    dateLastActivated: { type: 'number' },
    dateLastInactivated: { type: 'number' },
    groupId: { type: 'string' },
  },
  required: ['partnerPoolId', 'cameraSerialNumber', 'cameraState'],
};

export const CameraArraySchema: SchemaArrayI = {
  type: 'array',
  items: CameraSchema,
};
