// MODEL
import { GroupI } from './Group.model';

// SCHEMA
import { GroupSchema, GroupArraySchema } from './Group.schema';

// VALIDATION UTILS
import { getValidatedTypeFromJson, getValidatedArrayFromJson } from 'models/models.validation';

export function getValidatedGroupArray(json: unknown[]): GroupI[] {
  // console.log('getValidatedGroupBaseArray');
  // console.log('json', typeof json, Array.isArray(json));
  return getValidatedArrayFromJson<GroupI>(json, GroupArraySchema);
}

export function getValidatedGroup(json: unknown): GroupI {
  return getValidatedTypeFromJson(json, GroupSchema);
}
