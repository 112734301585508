// REDUX TYPES
import { createAction, ActionsUnionT } from "state/modules/module.actions";

// MODULE TYPES
import { GroupsActionType } from "./groups.types";

// MODELS
import { NormalizedGroups } from "models/Group/Group.normalize";
import { SetGroupGeotabCompanyIdParamsI } from "./groups.thunks";

// BASIC ACTION CREATORS

// GET GROUPS
export const getGroupsRequestActionCreator = () => createAction(GroupsActionType.GET_GROUPS_REQUEST);
export type getGroupsRequestActionT = ReturnType<typeof getGroupsRequestActionCreator>;

export const getGroupsSuccessActionCreator = (normalized: NormalizedGroups) => createAction(GroupsActionType.GET_GROUPS_SUCCESS, normalized);
export type getGroupsSuccessActionT = ReturnType<typeof getGroupsSuccessActionCreator>;

export const getGroupsFailureActionCreator = (errorMessage: string) => createAction(GroupsActionType.GET_GROUPS_FAILURE, { errorMessage });
export type getGroupsFailureActionT = ReturnType<typeof getGroupsFailureActionCreator>;

// CREATE GROUP 
export const createGroupRequestActionCreator = () => createAction(GroupsActionType.CREATE_GROUP_REQUEST);
export type createGroupRequestActionT = ReturnType<typeof createGroupRequestActionCreator>;

export const createGroupSuccessActionCreator = (normalized: NormalizedGroups) => createAction(GroupsActionType.CREATE_GROUP_SUCCESS, normalized);
export type createGroupSuccessActionT = ReturnType<typeof createGroupSuccessActionCreator>;

export const createGroupFailureActionCreator = (errorMessage: string) => createAction(GroupsActionType.CREATE_GROUP_FAILURE, { errorMessage });
export type createGroupFailureActionT = ReturnType<typeof createGroupFailureActionCreator>;

// CHANGE GROUP GROUP / UPDATE GROUP PERMISSION
export const removeAllCamerasRequestActionCreator = () => createAction(GroupsActionType.REMOVE_ALL_CAMERAS_REQUEST);
export type removeAllCamerasRequestActionT = ReturnType<typeof removeAllCamerasRequestActionCreator>;

export const removeAllCamerasSuccessActionCreator = (normalized: NormalizedGroups) => createAction(GroupsActionType.REMOVE_ALL_CAMERAS_SUCCESS, normalized);
export type removeAllCamerasSuccessActionT = ReturnType<typeof removeAllCamerasSuccessActionCreator>;

export const removeAllCamerasFailureActionCreator = (errorMessage: string) => createAction(GroupsActionType.REMOVE_ALL_CAMERAS_FAILURE, { errorMessage });
export type removeAllCamerasFailureActionT = ReturnType<typeof removeAllCamerasFailureActionCreator>;

// CHANGE GROUP GROUP / UPDATE GROUP PERMISSION
export const deleteGroupRequestActionCreator = (groupId: string) => createAction(GroupsActionType.DELETE_GROUP_REQUEST, { groupId });
export type deleteGroupRequestActionT = ReturnType<typeof deleteGroupRequestActionCreator>;

export const deleteGroupSuccessActionCreator = (groupId: string) => createAction(GroupsActionType.DELETE_GROUP_SUCCESS, { groupId });
export type deleteGroupSuccessActionT = ReturnType<typeof deleteGroupSuccessActionCreator>;

export const deleteGroupFailureActionCreator = (errorMessage: string) => createAction(GroupsActionType.DELETE_GROUP_FAILURE, { errorMessage });
export type deleteGroupFailureActionT = ReturnType<typeof deleteGroupFailureActionCreator>;

// ------------------------------------------------------------
// EXTERNAL PROVIDER ACTIONS
// ------------------------------------------------------------
export const setGroupGeotabCompanyIdRequestActionCreator = (group: SetGroupGeotabCompanyIdParamsI) => createAction(GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_REQUEST, group);
export type setGroupGeotabCompanyIdRequestActionT = ReturnType<typeof setGroupGeotabCompanyIdRequestActionCreator>;

export const setGroupGeotabCompanyIdSuccessActionCreator = (normalized: NormalizedGroups) => createAction(GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_SUCCESS, normalized);
export type setGroupGeotabCompanyIdSuccessActionT = ReturnType<typeof setGroupGeotabCompanyIdSuccessActionCreator>;

export const setGroupGeotabCompanyIdFailureActionCreator = (errorMessage: string) => createAction(GroupsActionType.SET_GROUP_GEOTAB_COMPANY_ID_FAILURE, { errorMessage });
export type setGroupGeotabCompanyIdFailureActionT = ReturnType<typeof setGroupGeotabCompanyIdFailureActionCreator>;

export const GroupsActionCreators = {
  getGroupsRequest: getGroupsRequestActionCreator,
  getGroupsSuccess: getGroupsSuccessActionCreator,
  getGroupsFailure: getGroupsFailureActionCreator,
  createGroupRequest: createGroupRequestActionCreator,
  createGroupSuccess: createGroupSuccessActionCreator,
  createGroupFailure: createGroupFailureActionCreator,
  removeAllCamerasRequest: removeAllCamerasRequestActionCreator,
  removeAllCamerasSuccess: removeAllCamerasSuccessActionCreator,
  removeAllCamerasFailure: removeAllCamerasFailureActionCreator,
  deleteGroupRequest: deleteGroupRequestActionCreator,
  deleteGroupSuccess: deleteGroupSuccessActionCreator,
  deleteGroupFailure: deleteGroupFailureActionCreator,
  setGroupGeotabCompanyIdRequest: setGroupGeotabCompanyIdRequestActionCreator,
  setGroupGeotabCompanyIdSuccess: setGroupGeotabCompanyIdSuccessActionCreator,
  setGroupGeotabCompanyIdFailure: setGroupGeotabCompanyIdFailureActionCreator,
};

const GroupActionCreators = {
  createGroupRequest: createGroupRequestActionCreator,
  createGroupSuccess: createGroupSuccessActionCreator,
  createGroupFailure: createGroupFailureActionCreator,
  removeAllCamerasRequest: removeAllCamerasRequestActionCreator,
  removeAllCamerasSuccess: removeAllCamerasSuccessActionCreator,
  removeAllCamerasFailure: removeAllCamerasFailureActionCreator,
  deleteGroupRequest: deleteGroupRequestActionCreator,
  deleteGroupSuccess: deleteGroupSuccessActionCreator,
  deleteGroupFailure: deleteGroupFailureActionCreator,
  setGroupGeotabCompanyIdRequest: setGroupGeotabCompanyIdRequestActionCreator,
  setGroupGeotabCompanyIdSuccess: setGroupGeotabCompanyIdSuccessActionCreator,
  setGroupGeotabCompanyIdFailure: setGroupGeotabCompanyIdFailureActionCreator,
}

export type GroupsActionsUnion = ActionsUnionT<typeof GroupsActionCreators>;

export type GroupActionsUnion = ActionsUnionT<typeof GroupActionCreators>;

export type GroupEntitiesActions = getGroupsSuccessActionT | createGroupSuccessActionT | deleteGroupSuccessActionT | setGroupGeotabCompanyIdSuccessActionT;