import { createSelector } from 'reselect';
import { AppStateI } from 'state/modules/root.reducer';
import { getCameraEntities } from './entities.selectors';
import { CAMERA_DEFAULT_GROUP, CameraWithGroupId } from 'models/Camera/Camera.model';

export const getCamerasFetchingState = (state: AppStateI) => state.cameras.isFetching;
export const getCamerasValidState = (state: AppStateI) => state.cameras.isValid;
export const getCameraItems = (state: AppStateI) => state.cameras.items;

export const shouldFetchCamerasSelector = createSelector(
  [getCamerasFetchingState, getCamerasValidState],
  (isFetching, isValid) => {
    let shouldFetch = false;
    if (!isFetching && !isValid) {
      shouldFetch = true;
    }
    return shouldFetch;
  },
);


export const getCamerasArray = createSelector(
  [getCamerasValidState, getCameraItems, getCameraEntities],
  (isValid, items, entities) => {
    let cameras: CameraWithGroupId[] = [];
    if (!isValid) {
      return cameras;
    }
    cameras = items.map((itemId) => {
      let cameraWithGroupId: CameraWithGroupId;
      const camera = entities[itemId];
      let groupId = CAMERA_DEFAULT_GROUP;
      if (camera.groupId) {
        groupId = camera.groupId;
      }
      cameraWithGroupId = {
        ...camera,
        groupId,
      }
      return cameraWithGroupId;
    });
    return cameras;
  }
)

export const getCameraSerialToDeviceIdMap = createSelector(
  [getCamerasArray],
  (cameras) => {
    let cameraSerialToDeviceIdMap: Map<string, string | undefined> = new Map();
    cameras.forEach((camera) => {
      cameraSerialToDeviceIdMap.set(camera.cameraSerialNumber, camera.cameraId);
    });
    return cameraSerialToDeviceIdMap;
  }
)
