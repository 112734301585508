// AMPLIFY
import { Auth } from 'aws-amplify';

// GATEWAY SERVICE
import { GatewayMethods, GatewayServiceResponseArrayI, GatewayServiceResponseI } from './gateway.types';
import { GatewayPathsPCP, getGatewayPCPInvokeUrl } from './gateway.config';

// UTILS
// import { safeObjectHasProperty } from 'utils/typesafe';

async function getAuthorizationFromAmplify(): Promise<string | undefined> {
  let identityToken: string | undefined;
  try {
    const session = await Auth.currentSession();
    console.log("session");
    console.log(session);
    identityToken = session.getIdToken().getJwtToken();
    return identityToken;
  } catch (err) {
    console.log("ERROR:getAuthorizationFromAmplify");
    console.log(err);
    throw err;
  }
}

export async function fetchWithAuthentication(method: GatewayMethods, url: string, body?: string) {
  
  let authorization: string | undefined;
  authorization = await getAuthorizationFromAmplify();
  if (!authorization) {
    alert('Missing Authorization Header');
    return;
  }

  // DEFINE OPTIONS
  const fetchOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authorization
    }
  }

  console.log('fetchOptions');
  console.log(fetchOptions);
  if (method === 'POST' && body) {
    fetchOptions.body = body;
  }

  return fetch(url, fetchOptions)
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('InvalidResponse');
      }
      return response.json();
    })
    .catch(err => {
      console.log(err);
      throw err;
    });
}


// export async function getResourcesFromService<K extends string, T extends GatewayServiceResponseArrayI<K>> (path: GatewayPathsPCP): Promise<T> {
export async function getResourcesFromService<K extends string> (path: GatewayPathsPCP, body?: string): Promise<GatewayServiceResponseArrayI<K>> {
  const url = getGatewayPCPInvokeUrl(path);
  return await fetchWithAuthentication('POST', url, body);
}

export async function postRequestToService<K extends string> (path: GatewayPathsPCP, body?: string): Promise<GatewayServiceResponseI<K>> {
  const url = getGatewayPCPInvokeUrl(path);
  return await fetchWithAuthentication('POST', url, body);
}

export async function postRequestToServiceWithoutResponse(path: GatewayPathsPCP, body?: string): Promise<{}> {
  const url = getGatewayPCPInvokeUrl(path);
  return await fetchWithAuthentication('POST', url, body);
}


// export async function getResourcesFromService<K extends string> (path: GatewayPathsPCP): Promise<GatewayServiceResponseArrayI<K>> {
//   const url = getGatewayPCPInvokeUrl(path);
//   return await fetchWithAuthentication('GET', url);
//   // let serviceResponse: GatewayServiceResponseArrayI<K>;
//   // try {
//   //   const json = await fetchWithAuthentication('GET', url);
//   //   return json;
//   //   // if (jsonToServiceResponseTypeGuard(json, resourceKey)) {
//   //   //   serviceResponse = json;
//   //   //   // console.log(serviceResponse);
//   //   //   return serviceResponse;
//   //   // } else {
//   //   //   throw new Error('InvalidServiceResponse');
//   //   // }
//   // } catch (err) {
//   //   console.log('ERROR:getRequestWithKey', path);
//   //   console.log(err);
//   //   throw err;
//   // }
// }