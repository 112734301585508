// REACT 
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// MATERIAL
import { Grid } from "@material-ui/core";

// APP COMPONENTS
import NavBar from 'app/components/Nav/NavBar';
import NavDrawer from "app/components/Nav/NavDrawer";

// PAGES
import CamerasPage from "app/pages/Cameras.page";
import UsersPage from "app/pages/Users.page";
import GroupsPage from "app/pages/Groups.page";

export default function RouterProvider() {
  return (
    <BrowserRouter>
      <NavBar />
      <Grid className="app-grid">
        <NavDrawer />
        <div className="app-content">
          <Switch>
            <Route exact path="/" component={CamerasPage} />
            <Route exact path="/cameras" component={CamerasPage} />
            <Route exact path="/users" component={UsersPage} />
            <Route exact path="/groups" component={GroupsPage} />
          </Switch>
        </div>
      </Grid>
    </BrowserRouter>
  );
}