// react
import React from 'react';

// MATERIAL COMPONENT
// import { Tooltip } from '@material-ui/core';

// MATERIAL STYLES
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// MATERIAL ICON
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface PropsI {
  isValid: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    valid: {
      margin: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
    invalid: {
      margin: theme.spacing(1),
      color: '#ff9800'
    },
  }),
);

export const ConditionalIcon: React.FC<PropsI> = ({ isValid }) => {
  const classes = useStyles();

  if (isValid) {
    return (
      <CheckCircleOutlineIcon className={classes.valid} fontSize="small" />
    )
  } else {
    return (
      <ErrorOutlineIcon className={classes.invalid} fontSize="small" />
    )
  }
}