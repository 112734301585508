// REACT-REDUX
import React, { Component } from 'react';

// MODELS
import { OwlPartnerPoolUserPermission, UserCreateParams } from 'models/User/User.model';

// MATERIAL COMPONENTS
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

// APP COMPONENTS
import UserCreateForm from 'app/components/Forms/UserCreateForm';

interface PropsI {
  isOpen: boolean;
  handleClose: () => void;
  handleCreateUser: (user: UserCreateParams) => void;
}

type State = UserCreateParams;

interface HandlerPropsI {
  handleChangePermission: (permission: OwlPartnerPoolUserPermission) => void;
  handleChangeTextField: (key: keyof State, value: string) => void;
}

export type UserCreateFormProps = State & HandlerPropsI;

export default class UserDialog extends Component<PropsI, State> {

  state: State = {
    permission: OwlPartnerPoolUserPermission.MANAGER,
    firstName: "",
    lastName: "",
    email: ""
  }

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState({
      permission: OwlPartnerPoolUserPermission.MANAGER,
      firstName: "",
      lastName: "",
      email: ""
    });
    handleClose();
  }

  handleChangeTextField = (key: keyof State, value: string) => {
    switch (key) {
      case 'firstName':
        return this.setState({ firstName: value });
      case 'lastName':
        return this.setState({ lastName: value });
      case 'email':
        return this.setState({ email: value });
      default:
        return;
    }
  }

  handleChangePermission = (permission: OwlPartnerPoolUserPermission) => {
    this.setState({ permission });
  }

  handleCreateUser = () => {
    const { handleCreateUser } = this.props;
    handleCreateUser(this.state);
  }

  render() {
    const { isOpen, handleClose } = this.props;
    const { firstName, lastName, email } = this.state;
    const isUserValid = firstName.length > 1 && lastName.length > 1 && email.length > 5;
    return (
      <Dialog open={isOpen} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">Create User</DialogTitle>
        <DialogContent>
          <UserCreateForm 
            {...this.state}
            handleChangeTextField={this.handleChangeTextField}
            handleChangePermission={this.handleChangePermission}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateUser} color="secondary" disabled={!isUserValid}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}