// REACT
import React from 'react';

// MATERIAL COMPONENT
import { TextField } from '@material-ui/core';

interface PropsI<S extends object, K extends keyof S> {
  propertyKey: K;
  value: string;
  label: string;
  className?: string;
  handleChange: (key: K, value: string) => void;
}

export class TextFieldSafe<S extends object, K extends keyof S> extends React.Component<PropsI<S,K>> {

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { propertyKey, handleChange } = this.props;
    handleChange(propertyKey, event.currentTarget.value);
  }

  render() {
    const { propertyKey, label, value, className = "" } = this.props;
    const id = `tsf-${propertyKey}`;
    return (
      <TextField
        id={id}
        label={label}
        value={value}
        autoComplete={'off'}
        onChange={this.handleChange}
        margin="none"
        className={className}
      />
    )
  }
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface StateI {
  isFocused: boolean;
  isDirty: boolean;
}

const initialState: StateI = {
  isFocused: false,
  isDirty: false,
}

export class TextFieldSafeEmail<S extends object, K extends keyof S> extends React.Component<PropsI<S,K>, StateI> {
  
  state  = initialState

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { propertyKey, handleChange } = this.props;
    handleChange(propertyKey, event.currentTarget.value);
    this.setState({ isDirty: true })
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  }

  handleBlur = () => {
    this.setState({ isFocused: false });
  }

  render() {
    const { propertyKey, label, value } = this.props;
    const { isFocused, isDirty } = this.state;
    const id = `tsf-${propertyKey}`;
    let isValid = true;
    if (isDirty && !isFocused) {
      isValid = EMAIL_REGEX.test(value);
    }
    return (
      <TextField
        id={id}
        label={label}
        value={value}
        type="email"
        name="email"
        required
        autoComplete={'off'}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        margin="none"
        error={!isValid}
      />
    )
  }
}