// REDUX
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// MODULE TYPES
import { AppStateI } from 'state/modules/root.reducer';
// import { GetState } from 'state/modules/module.reducers';

// ACTIONS
import { CamerasActionCreators } from './cameras.actions';

// SELECTORS
// import { shouldFetchCamerasSelector } from 'state/selectors/modules/camera.selectors';

// SERVICE
import { getCamerasFromService, updateCameraGroupWithService, updateCameraMetadataWithService } from 'services/gateway/modules/cameras.service';

// NORMALIZED RESPONSE
import { NormalizedCameras } from 'models/Camera/Camera.normalize';

export const getCamerasThunk = (groupId?: string) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('getCamerasThunk');
  dispatch(CamerasActionCreators.getCamerasRequest());

  let normalizedCameras: NormalizedCameras;
  try {
    normalizedCameras = await getCamerasFromService(groupId);
    dispatch(CamerasActionCreators.getCamerasSuccess(normalizedCameras));
    // setTimeout(() => {
    //   dispatch(CamerasActionCreators.getCamerasSuccess(normalizedCameras));
    // }, 1500);
  } catch (err) {
    dispatch(CamerasActionCreators.getCamerasFailure(err.name));
  }
};


export const addCameraToGroupThunk = (cameraSerialNumber: string, groupId: string) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('addCameraToGroupThunk');
  dispatch(CamerasActionCreators.addCameraToGroupRequest(cameraSerialNumber, groupId));

  let normalizedCameras: NormalizedCameras;
  try {
    normalizedCameras = await updateCameraGroupWithService(cameraSerialNumber, groupId);
    dispatch(CamerasActionCreators.addCameraToGroupSuccess(normalizedCameras));
  } catch (err) {
    dispatch(CamerasActionCreators.addCameraToGroupFailure(err.name));
  }
};

export const removeCameraFromGroupThunk = (cameraSerialNumber: string) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('removeCameraFromGroupThunk');
  dispatch(CamerasActionCreators.removeCameraFromGroupRequest(cameraSerialNumber));

  let normalizedCameras: NormalizedCameras;
  try {
    normalizedCameras = await updateCameraGroupWithService(cameraSerialNumber);
    dispatch(CamerasActionCreators.removeCameraFromGroupSuccess(normalizedCameras));
  } catch (err) {
    dispatch(CamerasActionCreators.removeCameraFromGroupFailure(err.name));
  }
};


export const updateCameraMetadataThunk = (cameraSerialNumber: string, externalMetadata: string | undefined) => async (dispatch: ThunkDispatch<AppStateI, void, Action>) => {
  console.log('updateCameraMetadataThunk');
  dispatch(CamerasActionCreators.updateCameraMetadataRequest(cameraSerialNumber, externalMetadata));

  let normalizedCameras: NormalizedCameras;
  try {
    normalizedCameras = await updateCameraMetadataWithService(cameraSerialNumber, externalMetadata);
    dispatch(CamerasActionCreators.updateCameraMetadataSuccess(normalizedCameras));
  } catch (err) {
    dispatch(CamerasActionCreators.updateCameraMetadataFailure(err.name));
  }
};