import Ajv from 'ajv';

// CONFIGURE AJV
const ajv = new Ajv({ allErrors: true });

export interface ValidationResult {
  isValid: boolean;
  errors: Ajv.ErrorObject[] | undefined | null;
}

interface SchemaI {
  type: string;
  properties?: {
    [extraProps: string]: { type: string };
  };
  required?: string[];
}

export interface SchemaArrayI {
  type: 'array';
  items: SchemaI;
}

export function validateWithSchema<T>(json: T, schema: SchemaI): ValidationResult {
  // console.log('validateWithSchema');
  try {
    const test = ajv.compile(schema);
    const isValidTest = test(json);
    let isValid = false;
    if (typeof isValidTest === 'boolean' && isValidTest) {
      isValid = true;
    }

    return {
      isValid,
      errors: test.errors,
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function validationTypeGuard<T>(json: T | unknown, ajvResult: ValidationResult): json is T {
  return ajvResult.isValid;
}

export function getValidatedTypeFromJson<T>(json: unknown, schema: SchemaI): T {
  const results = validateWithSchema(json, schema);
  if (validationTypeGuard<T>(json, results)) {
    return json;
  } else {
    throw new Error('InvalidProps');
  }
}

export function validationTypeGuardForArray<T>(json: unknown[] | T[], ajvResult: ValidationResult): json is T[] {
  return ajvResult.isValid;
}

export function getValidatedArrayFromJson<T>(json: unknown[], schema: SchemaArrayI): T[] {
  const results = validateWithSchema(json, schema);
  if (validationTypeGuardForArray<T>(json, results)) {
    return json;
  } else {
    console.log(json);
    console.log(results.errors);
    throw new Error('InvalidProps');
  }
}
