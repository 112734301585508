import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// MATERIAL STYLES
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
export interface DropdownSelectorItemI {
  value: number | string;
  displayValue: number | string;
}

interface PropsI {
  label: string;
  value: number | string;
  items: DropdownSelectorItemI[];
  placeholderItem?: string;
  handleChange: (value: any) => void;
}

export const DropdownSelector: React.FC<PropsI> = ({ label, value, items, placeholderItem, handleChange }) => {
  const handleChangeWrapper = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    console.log('event.target.name', event.target.name);
    console.log('event.target.value', event.target.value);
    handleChange(event.target.value);
  };

  return (
    <FormControl >
        <InputLabel shrink htmlFor="ds-label-placeholder">
          {label}
        </InputLabel>
        <Select
          value={value}
          onChange={handleChangeWrapper}
          displayEmpty
          name="ds"
        >
          {placeholderItem && (
            <MenuItem value={""}>{placeholderItem}</MenuItem>
          )}
          {items.map((item, index) => {
            const key = `ds-${index}-${item.value}`
            return (
              <MenuItem key={key} value={item.value}>{item.displayValue}</MenuItem>
            )
          })}
        </Select>
        {/* <FormHelperText>Label + placeholder</FormHelperText> */}
      </FormControl>
  )
}