// MODEL
import { CameraI } from './Camera.model';

// SCHEMA
import { CameraSchema, CameraArraySchema } from './Camera.schema';

// VALIDATION UTILS
import { getValidatedTypeFromJson, getValidatedArrayFromJson } from 'models/models.validation';

export function getValidatedCameraArray(json: unknown[]): CameraI[] {
  // console.log('getValidatedCameraBaseArray');
  // console.log('json', typeof json, Array.isArray(json));
  return getValidatedArrayFromJson<CameraI>(json, CameraArraySchema);
}

export function getValidatedCamera(json: unknown): CameraI {
  return getValidatedTypeFromJson(json, CameraSchema);
}
